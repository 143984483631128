import styled from "styled-components";

export const Wrap = styled.div``;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
  }

  /* @media(max-width: 600px) {
    grid-template-columns: 1fr;
  } */
`;

export const SectionTitle = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  /* font-size: 2.3rem; */
  font-weight: 300;

  b {
    font-weight: 500;
  }

  @media (max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SectionTitleMobile = styled.h3`
  text-transform: uppercase;
  font-weight: 300;

  b {
    font-weight: 500;
  }

  @media (min-width: 600.99px) {
    display: none;
  }
`;

export const VisualContent = styled.div`
   img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  > div {
    width: 100% !important;
    max-width: 600px;
    height: auto !important;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin-inline: auto;
    order: 1;
  }
`;

export const TextContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

   @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const Card = styled.div`
  padding: 3rem;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;

  &:not(.hover-none):hover {
    cursor: pointer;
  }

  &.selected,
  &:not(.hover-none):hover {
    box-shadow: 0px 20px 40px 0px rgba(2, 51, 42, 0.1);
  }
  .selected-img-on-mobile {
    max-width: 80%;
    margin-inline: auto;
    width: 100%;
    display: block;
  }

  @media (min-width: 768.99px) {
    .selected-img-on-mobile {
      display: none;
    }
  }
`;
