import React from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import Chip from "components/general/formComponents/chip/Chip";
import { getS3ImageUrl } from "configs/utils";
import * as F from "./coachingWithMe.style";
// Images
import Coach from "assets/images/placeholder.jpg";

const CoachingWithMe = () => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_lp_picture_1
    ? getS3ImageUrl(coach?.cw_lp_picture_1)
    : Coach;

  const ITEMS = [
    "Individueller Ernährungsplan für 12 - Wochen",
    "Rezeptfilter mit künstlicher Intelligenz",
    "Dynamische Kalorienanpassung anhand deiner Bedürfnisse",
  ];

  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="Mein Coaching" margin="0 0 3rem 0" bg="#fff" />
        <F.Title className="ct-text-block font-bold color-dark text-left text-5xl">
          Dein persönlicher
          <br /> Ernährungscoach
          <div id="code_block-13-112" class="ct-code-block absolute-tr">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9rem"
              height="8.5rem"
              viewBox="0 0 136.919 134.632"
            >
              <g
                id="Gruppe_35340"
                data-name="Gruppe 35340"
                transform="translate(-687.378 -296.926)"
              >
                <text
                  id="_"
                  data-name="🚀"
                  transform="translate(763.297 356.926)"
                  fill="#142c1f"
                  font-size="60"
                  font-family="AppleColorEmoji, Apple Color Emoji"
                >
                  <tspan x="0" y="0">
                    🚀
                  </tspan>
                </text>
                <path
                  id="Pfad_30511"
                  data-name="Pfad 30511"
                  d="M766.4,373.926S740.38,407.11,720.145,390.8s40.678-17.551,40.471-3.3S706.1,431.062,688.965,430.03"
                  fill="none"
                  stroke="#a5b5af"
                  stroke-linecap="round"
                  stroke-width="3"
                  stroke-dasharray="5"
                ></path>
              </g>
            </svg>
          </div>
        </F.Title>
        <F.SubTitle className="ct-text-block color-paragraph text-base text-left">
          Foodiary unterstütz dich, deine Ernährung langfristig gesunder zu
          gestalten und dabei dein körperliches Ziel zu erreichen.
        </F.SubTitle>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Wir von Foodiary wissen, wie schwer der erste Schritt der
          Ernährungsumstellung sein kann. Deshalb unterstützen wir dich mit
          unseren Ernährungscoaches dabei, diesen so einfach wir möglich zu
          gestalten. Gemeinsam erstellen wir deinen persönlichen Ernährungsplan,
          beantworten deine Fragen und begleiten dich auf dem Weg deine
          Ernährung dauerhaft umzustellen.
        </F.Description>

        <div id="div_block-24-112" class="ct-div-block w-full mt-14">
          {ITEMS.map((text) => (
            <div id="div_block-25-112" class="ct-div-block oxel_iconlist__row">
              <div id="code_block-26-112" class="ct-code-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4.6rem"
                  height="4.6rem"
                  viewBox="0 0 46 46"
                >
                  <g
                    id="Gruppe_8254"
                    data-name="Gruppe 8254"
                    transform="translate(-702.831 -3690.77)"
                  >
                    <circle
                      id="Ellipse_529"
                      data-name="Ellipse 529"
                      cx="23"
                      cy="23"
                      r="23"
                      transform="translate(702.831 3690.77)"
                      fill="#142c1f"
                    ></circle>
                    <path
                      id="Icon_ionic-ios-checkmark"
                      data-name="Icon ionic-ios-checkmark"
                      d="M30.329,14l-1.635-1.681a.351.351,0,0,0-.26-.111h0a.337.337,0,0,0-.26.111L16.843,23.732l-4.124-4.124a.359.359,0,0,0-.52,0l-1.653,1.653a.37.37,0,0,0,0,.529l5.2,5.2a1.645,1.645,0,0,0,1.087.529,1.724,1.724,0,0,0,1.077-.511h.009L30.338,14.528A.4.4,0,0,0,30.329,14Z"
                      transform="translate(705.396 3693.963)"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </div>
              <div
                id="text_block-27-112"
                class="ct-text-block foodiary-list-item text-base color-dark font-medium text-left pl-5"
              >
                {text}
              </div>
            </div>
          ))}
        </div>
      </F.ContentSection>
      {/* <F.TitleMobile className="ct-text-block font-bold color-dark text-left text-5xl">
        Foodiary Coaching <br /> mit <span>{coach?.prename}</span>
      </F.TitleMobile> */}
      <F.VisualContent>
        {/* <img src={imgUrl} alt="coach" /> */}
        <ReactPlayer
          url={"https://foodiary.app/data/Foodiary-konzept.mp4"}
          playsinline
          playing
          muted
          loop
        />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default CoachingWithMe;
