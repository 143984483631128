import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    height: 500px;
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: 400px;
  }
`

export const Image = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: relative;
  height: 100%;

  .shape, .img {
    position: absolute;
    top: 0;
    left: 0;
  }

  .shape {
    z-index: 12;
    width: 100%;
    height: 100%;
  }

  .img {
    width: 99%;
    height: 99%;
    margin: .5% auto 0;
    display: block;
    object-fit: cover;
    object-position: top;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 40px;
  
`
export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
   
  font-size: 18px;
  color: #7a8780;
  margin: 35px 0 20px;
`

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
  }

  @media (max-width: 991.99px) {
    order: 2;
  }
`

export const PlanBoxIcons = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`

export const PlanBoxIconBox = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const PlanBoxInformation = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
  width: calc(100% - 40px);
  padding-left: 20px;
  font-size: 20px;
  font-family: 'RubikBold';

  @media (max-width: 991.99px) {
  }
`
