import { useHistory } from "react-router-dom";
import * as C from "./SliderCoachCard.style";
import DefaultUserProfile from "assets/outer-pages/png/default.png";

const SliderCoachCard = ({ coach, baseUrl, isWidth }) => {
  const history = useHistory();

  const imgUrl = coach?.profilePic
    ? `${baseUrl}${coach?.profilePic}`
    : DefaultUserProfile;

  return (
    <C.CardNew
      className={isWidth ? "text-center item-w h-100" : "text-center"}
      onClick={() =>
        history.push(`/coaches/${coach?.prename}-${coach?.surname}-${coach?.id}`, {
          state: { coachId: coach?.id },
        })
      }
    >
      <img src={imgUrl || DefaultUserProfile} alt="" />
      <div className="chip bg-green-chip mx-auto">NEU</div>
      <h3 className="text-xl">
        {coach?.prename} <br /> {coach?.surname}
      </h3>
      <C.CoachTagBox className="flex-wrap">
        <C.CoachTag className="subtitle-desktop">{coach?.subtitle}</C.CoachTag>
        <C.CoachTag className="subtitle-mobile">
          {coach?.subtitle?.split(" ")[0]}
        </C.CoachTag>
        <C.CoachTag className="step1">{coach?.step1}</C.CoachTag>
      </C.CoachTagBox>
    </C.CardNew>
  );
};

export default SliderCoachCard;
