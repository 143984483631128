export const LOGIN = "/login";
export const ROOT = "/";
export const MY_COACHING = "/mein-coaching";
export const INITIAL_CONSULTATION = "/erstgespraech";
export const COACHING_PRO = "/coaching-pro";
export const COACHING_FINDEN = "/coach-finden";
export const FAQ = "/faq";
export const SUCCESS = "/successful-booking-pro-r&narim0ken8";
export const COACHING_PRO_APP = "/coaching-pro/app";
export const COACHING_PRO_COACHING = "/coaching-pro/coaching";
export const COACHES = "/coaches";
export const COACHES_SLUG = "/coaches/:slug";