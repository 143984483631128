import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  align-items: center;
  justify-content: center;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`


export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 40px;
  
  margin-bottom: 35px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`
export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
   
  font-size: 18px;
  color: #7a8780;
  padding-bottom: 30px;
`


export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  height: 600px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767.99px) {
    height: 400px;
  }
`