import styled from "styled-components";

export const Wrap = styled.div``;

export const CardsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  justify-content: space-between;
  margin-top: 80px;
  background: #f0f4f3;
  border-radius: 30px;
  padding: 80px 60px;
  align-items: center;

  @media (max-width: 991.99px) {
  }

  @media (max-width: 767px) {
    margin-top: 40px;
    padding: 40px 20px;
    grid-template-columns: 1fr;
  }
`;

export const SectionTitle = styled.h3`
  text-align: center;
  color: #142c1f;
  text-transform: uppercase;
  b {
    font-weight: 600;
  }

  @media (max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
export const ConsultationBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 9rem;
  }

  h4 {
    margin: 30px 0 20px;
    @media (max-width: 600px) {
      margin: 30px 0 15px;
    }
  }

  p {
  }
`;

export const ConsultationBoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    margin: 40px 0px;
  }
  @media (max-width: 600px) {
    margin: 40px 0px;
    transform: rotate(90deg);
    img {
      width: 50px;
    }
  }
`;
export const MobileText = styled.p`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;
