import React from "react"
import * as F from "./healthScore.styled"
import Chip from "components/general/formComponents/chip/Chip"
// Images
import HealthScore from "assets/images/partnership/foodiary-anamnese.svg"

const CoachingAccordian = () => {
  return (
    <F.CoachingAccordianContainer>
      <F.VisualContent>
        <img src={HealthScore} alt="plan" />
      </F.VisualContent>
      <F.TextContent>
        <Chip text="ERNÄHRUNGSPLAN" margin="0 0 30px 0" />
        <F.Title>Was ist der Health Score?</F.Title>
        <F.Info>
          Auf Basis deiner Aktivität, Essgewohnheiten und körperlichen Daten ermitteln wir eine Health Score. Diese gibt an, wie gesund dein aktueller Lebensstil und deine Ernährung ist. Du erhältst Tipps, wie diese verbessert werden kann um so dein gesetztes Fitnessziel einfacher und gesünder zu erreichen.
        </F.Info>
      </F.TextContent>
      
    </F.CoachingAccordianContainer>
  )
}

export default CoachingAccordian
