import styled from "styled-components"

const Card = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  box-shadow: 15px 15px 30px #02332A1A;
  padding: 1.5rem;
  border-radius: 8px;
  background: white;

  @media (max-width: 600px) {
    padding: 1rem 0.5rem;
  }

  h3 {
     
    margin: 20px 0 20px;
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 19px;
      margin-top: 0.4rem;
      margin-bottom: 0;
    }
  }

  > img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 6px solid #e5e5e540;

    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
    }
  }

  button {
    font-size: 1rem;
     
    @media (max-width: 600px) {
      width: fit-content;
      font-size: 0.9rem;
      padding: 0.6rem 1rem;
      border-width: 1px;
      margin-top: 10px;
      /* display: none; */
    }
  }
`

const CouchItem = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: #f8f8f8;
  border-radius: 4px;
  padding: 0.6rem 0.9rem;

  * {
     
  }

  small {
    color: #a5b5af;
    font-size: 1rem;
  }

  p {
    margin-top: 0.5rem;
    color: #1c9d87;
    font-size: 1rem;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const CouchItemForMobile = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  * {
     
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: #a5b5af;
    margin-top: 0.3rem;
  }

  @media (min-width: 601px) {
    display: none;
  }
`

const CouchStats = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  * {
     
  }

  div {
    width: calc(50% - 1px - 0rem);
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.5rem;

    small {
      color: #a5b5af;
      font-size: 0.86rem;
    }
    p {
      margin-top: 0.3rem;
      color: #44554c;
      font-size: 14px;
      font-weight: 600;
      min-height: 45px;
      word-break: break-word;
    }
    &:nth-child(2) {
      border-right: none;
    }
    &:nth-child(3) {
      border-bottom: none;
    }
    &:nth-child(4) {
      border-bottom: none;
      border-right: none;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export { Card, CouchItem, CouchStats, CouchItemForMobile }
