import React, { useEffect } from "react"

import Navbar from "components/Navbar/Navbar"
import Footer from "components/Footer/Footer"
import CouchFilters from "components/CoachFilters/CoachFilters"
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper"
import NextSteps from "components/NextSteps/NextSteps"
import CoachList from "components/CoachList/CoachList"

import * as G from "styles/General.Styles"
import * as C from "./coachFinder.styles"

const CoachFinden = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <G.Container>
                <Navbar />
                <SectionWrapper margin="60px 0 80px" mobileMargin="50px 0 20px">
                    <C.ShadowBackgroundWrap>
                        <C.PageTitle>Vereinbare dein Erstgespräch</C.PageTitle>
                        <C.PageDescription>
                            Wähle deinen persönlichen Ernährungsberater, welcher dich bei deiner <br />
                            Ernährungsumstellung mit wertvollen Tipps und Tricks unterstützt.
                        </C.PageDescription>
                        <C.ShadowBackground />
                    </C.ShadowBackgroundWrap>
                </SectionWrapper>
                <SectionWrapper margin="60px 0 0" mobileMargin="30px 0 0">
                    <CouchFilters />
                </SectionWrapper>
                <SectionWrapper margin="0 0 60px">
                    <CoachList />
                </SectionWrapper>
                <SectionWrapper margin="60px 0 80px">
                    <NextSteps />
                </SectionWrapper>
            </G.Container>
            <Footer />
        </>
    )
}

export default CoachFinden
