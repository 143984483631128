import React, { useEffect } from 'react';
import { useDispatch } from "react-redux"
// Components
import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper"
import FreeInitialConsultation from 'components/FreeInitialConsultation/FreeInitialConsultation';
import NewAttitude from 'components/NewAttitude/NewAttitude';
import FirstMeeting from 'components/FirstMeeting/FirstMeeting';
import HealthScore from 'components/HealthScore/HealthScore';
import ScheduleFirstConsultation from 'components/ScheduleFirstConsultation/ScheduleFirstConsultation';
import ConsultationReallyFree from 'components/ConsultationReallyFree/ConsultationReallyFree';
import StepCarMobile from 'components/CardSlider/CardSlider';
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";

// Styles
import * as G from 'styles/General.Styles';
import * as C from './initialConsultation.styles'
// Redux
import * as CoachActions from "redux/coach/coach.actions"

const InitialConsultation = (props) => {
    
    const dispatch = useDispatch()
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        dispatch(
            CoachActions.coachFeedbackRequest({
                callback: () => null,
            })
        )
    }, [dispatch])

    return (
        <>
            <G.Container>
                <Navbar />
                <SectionWrapper mobileMargin="40px 0 0" margin="100px 0 0px">
                    <FreeInitialConsultation />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0px">
                    <NewAttitude />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0px">
                    <FirstMeeting />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0px">
                    <HealthScore />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0px">
                    <C.desktopShow>
                        <ScheduleFirstConsultation className="desktopShow"/>
                    </C.desktopShow>
                    <C.mobileShow>
                        <StepCarMobile className="mobileShow"/>
                    </C.mobileShow>
                </SectionWrapper>
                <SectionWrapper mobileMargin="40px 0 0" margin="130px 0 0px">
                    <ConsultationReallyFree />
                </SectionWrapper>
                <SectionWrapper mobileMargin="40px 0 40px" margin="100px 0 40px">
                    <TestimonialSlider />
                </SectionWrapper>
            </G.Container>
            <Footer />
        </>
    )
}

export default InitialConsultation;
