import React from "react"
import { PopupButton } from "react-calendly"
import * as F from "./coachingPlansPro.styled"
import * as G from "styles/General.Styles"
// Images
import GreenTick from "assets/images/myCoaching/Icon-check.svg"
import CrossIcon from "assets/images/myCoaching/Icon-Cross.svg"
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg"

const CoachingPlansPro = () => {
  return (
    <F.CoachingPlansBox className="plansBox">
      <F.CoachingPlansBoxInner>
        <F.PlanBox className="nonActive">
          <F.PlanBoxHead>
            <F.TitleInfo>ERSTGESPRÄCH</F.TitleInfo>
            <F.Title>Kostenlos</F.Title>
            <F.SubTitle>einmalig</F.SubTitle>
          </F.PlanBoxHead>
          <F.PlanLowerBox>
            <G.PlanBoxIcons>
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTick} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>Erstgespräch</G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTick} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Unterstützung beim Erstellen deines Ernährungsplanes
                </G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTick} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Erklärung aller Funktionen der Foodiary App
                </G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={CrossIcon} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Persönlicher Coach für 3 Monate
                </G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={CrossIcon} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
                </G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={CrossIcon} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Foodiary PRO Funktionen
                </G.PlanBoxInformation>
              </li>
            </G.PlanBoxIcons>
          </F.PlanLowerBox>
        </F.PlanBox>
        <F.PlanBox className="active">
          <F.PlanBoxHead>
            <F.TitleInfo className="active">FOODIARY COACHING PRO</F.TitleInfo>
            <F.Title>150,00 €</F.Title>
            <F.SubTitle>pro Monat</F.SubTitle>
          </F.PlanBoxHead>
          <F.PlanLowerBox>
            <G.PlanBoxIcons>
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>Erstgespräch</G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Unterstützung beim Erstellen deines Ernährungsplanes
                </G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Erklärung aller Funktionen der Foodiary App
                </G.PlanBoxInformation>
              </li>

              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Persönlicher Coach für 3 Monate
                </G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
                </G.PlanBoxInformation>
              </li>
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation>
                  Foodiary PRO Funktionen
                </G.PlanBoxInformation>
              </li>
            </G.PlanBoxIcons>
          </F.PlanLowerBox>
        </F.PlanBox>
      </F.CoachingPlansBoxInner>
      <PopupButton
          className="btn-primary"
          styles={{
            fontSize: "17px",
            backgroundColor: "transparent",
            color: "#ffffff",
            fontFamily: "RubikRegular",
            width: "100%",
            margin: "30px 0 0"
          }}
          url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
          rootElement={document.getElementById("root")}
          text="Jetzt Termin vereinbaren"
        />
    </F.CoachingPlansBox>
  )
}

export default CoachingPlansPro
