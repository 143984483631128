import React from "react"
import { PopupButton } from "react-calendly"
import StepCard from "components/general/stepCard/StepCard"
// Images
import IconFood from "assets/images/myCoaching/Icon-food.svg"
import * as F from "./coachingProNow.style"

const CoachingProNow = () => {
  return (
    <F.Wrap>
      <F.SectionTitle>
        SO EINFACH FUNKTIONIERT'S  <br /> <b>BUCHE JETZT DEIN COACHING PRO</b>
      </F.SectionTitle>
      <F.SectionTitleMobile>
        STARTE JETZT GEMEINSAM MIT MIR <br /> <b>DEIN 12- WOCHEN COACHING!</b>
      </F.SectionTitleMobile>
      <F.SubInfo>
        In drei Schritten erfährst du, wie du dein kostenloses Erstgespräch mit deinem Ernährungsberater vereinbarst und du zeitnah mit ihm deine Ernährungsumstellung starten kannst.
      </F.SubInfo>
      <F.CardsWrap>
        <StepCard
          title="Coaching buchen"
          description="Entscheide dich dafür, gemeinsam mit uns dein Ziel zu erreichen. Buche deinen Wunschtermin."
          icon={IconFood}
        />
        <StepCard
          title="Ernährungsplan erstellen"
          description="In einem Video-Call erstellt dein Ernährungscoach deinen persönlichen Ernährungsplan."
          icon={IconFood}
        />
        <StepCard
          title="Starte mit deinem Coach"
          description="Wir beantworten dir deine Fragen und starten mit deinem 3-Monatigem Coaching."
          icon={IconFood}
        />
      </F.CardsWrap>
        <PopupButton
          className="btn-primary d-block"
          styles={{ margin: '50px auto 0'}}
          url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
          rootElement={document.getElementById("root")}
          text="Termin vereinbaren"
        />
    </F.Wrap>
  )
}

export default CoachingProNow
