import React from "react"

import * as C from "./yoyoEffectNew.styled"
// Images
import FoodiaryHealthy from "assets/images/partnership/foodiary-healthy.png"

const YoyoEffect = () => {
  return (
    <C.Wrap>
      <C.VisualContent>
        <img src={FoodiaryHealthy} alt="graph" />
      </C.VisualContent>
      <C.TextContent>
        <C.Title>Der Jojo-Effekt hat keine Chance!</C.Title>
        <C.Description>
          Während deinem 3-monatigen Coaching wirst du nicht nur dein gesetztes Ziel erreichen, sondern du lernst auch deine Ernährung dauerhaft umzustellen. In unserer Academy bringen wir dir alles über die Grundlagen der gesunden Ernährung bei, sodass du nach dem Coaching dein Ziel erreicht hast und selbst ein Experte im Bereich der Ernährung bist.
        </C.Description>
        <C.Description>
          So kannst du eigenständig deine zukünftige Ernährung planen und fällst nicht auf die fiesen Tricks der Lebensmittelindustrie rein.
        </C.Description>
      </C.TextContent>
    </C.Wrap>
  )
}

export default YoyoEffect
