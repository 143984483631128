import styled from "styled-components";

export const AccordianContainer = styled.div``;

export const AccordianBox = styled.div`
  border-bottom: 1px solid #dddddd;
  p {
    color: #44554c;
    a {
      color: #2ecf96;
    }
  }
  &:last-child {
    border: none;
  }
`;

export const AccordianHeader = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .IconPlus {
    width: 1.5rem;
    height: 1.5rem;
    background-image: "assets/images/myCoaching/Icon-plus.svg";
  }
  .IconClose {
    width: 2rem;
    height: 2rem;
    background-image: "assets/images/myCoaching/Icon-Close.svg";
    cursor: pointer;
  }
`;

export const AccordianTitle = styled.h4`
  color: #142c1f;

  width: calc(100% - 30px);

  ${({ active }) => active && `color: #2ecf96;`}
`;

export const AccordianButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
export const VisualContent = styled.div`
  margin-top: 32px;
  margin-bottom: 52px;
  img {
    width: 100%;
    height: auto;
    max-height: 360px;
    max-width: 300px;
    margin-inline: auto;
    display: block;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
