import React from "react"

import * as C from "./standardProgramNew.styled"
// Images
import Coach from "assets/images/partnership/foodiary-teamwork.png"
import GreenTick from "assets/images/myCoaching/Icon-check.svg"
// import ShapeOutline from "assets/images/pngs/shape-outline11px.png"

const StandardProgram = () => {

  return (
    <C.Wrap>
      {/* <C.VisualContent>
        <C.Image>
          <img className="shape" src={ShapeOutline} alt="Shape" />
          <img className="img" src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        </C.Image>
      </C.VisualContent> */}
      <C.VisualContentWithoutShape>
        <img className="img" src={Coach} alt="Foodiary-Coaching-Bild2" />
      </C.VisualContentWithoutShape>
      <C.TextContent>
        <C.Title>Persönlichkeit statt Standard-Programm</C.Title>
        <C.Description>
          Bei unserem Coaching PRO handelt es sich um kein vorgefertigtes Programm, sondern um eine 100 % persönliche Ernährungsbetreuung. Du erhältst einen persönlichen Ernährungsplan mit über 1700 leckeren Rezepten und einen festen Ernährungsberater, welcher dich in die kommende 3 Monate begleitet dein Ziel zu erreichen.
        </C.Description>
        <C.PlanBoxIcons>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation>
              Plan wird persönlich für dich erstellt.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation>
              Über 1700 abwechslungsreiche Rezepte
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation>
              Persönlicher Ernährungsberater an deiner Seite
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
      </C.TextContent>
    </C.Wrap>
  )
}

export default StandardProgram
