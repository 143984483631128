import moment from "moment"
import { PopupButton } from "react-calendly"
import { useHistory } from "react-router-dom"
import {
  Card,
  CouchItem,
  CouchStats,
  CouchContent,
  Actions,
  CouchProfile,
  IconActions,
} from "./CouchInfo.style"
import DefaultUserProfile from "assets/outer-pages/png/default.png"
import IconWeightLose from "assets/images/Icon-weightlose.svg"
import CouchIcon from "assets/outer-pages/svg/Abnehmen-Icon.svg"
import InstagramIcon from "assets/outer-pages/svg/Instagram-Icon.svg"
import MailIcon from "assets/outer-pages/svg/Mail-Icon.svg"
import ShareIcon from "assets/outer-pages/svg/Share-Icon.svg"
import { APPEND_CALENDLY_URL } from "constants"

const CouchInfo = ({ coach, baseUrl, onPaymentModal }) => {
  const history = useHistory()

  const imgUrl = coach?.profilePic
    ? `${baseUrl}${coach?.profilePic}`
    : DefaultUserProfile

  // const imgUrl = coach?.profilePic
  //   ? `${baseUrl}${coach?.imgpath}/${coach?.profilePic}.${coach?.imgtype}`
  //   : UserProfileImage

  return (
    <Card className="d-flex">
      <CouchContent>
          <span
            className="ct-link-text color-paragraph-alt back-link mt-8"
            onClick={() => history.push(`/`)}
          >
            {`<< zurück zu den Coaches`}
          </span>
          <h2 className="ct-text-block font-bold color-dark mt-8 text-5xl">
            {coach?.prename} {coach?.surname}
          </h2>
          <small>{moment().diff(coach.birthday, "years")} Jahre</small>
          <CouchItem className="d-flex align-items-center">
            <div className="coach-icon d-flex align-items-center justify-content-center">
              <img src={IconWeightLose} alt="weight loss" />
            </div>
            <div>
              <small className="">Coach für</small>
              <p className="mb-0">{coach?.subtitle}</p>
            </div>
          </CouchItem>
          <CouchStats className="d-grid">
            <div className="d-flex flex-column">
              <small>Spezialgebiet</small>
              <p className="mb-0">{coach?.step1}</p>
            </div>
            <div className="d-flex flex-column">
              <small>Coach seit</small>
              <p className="mb-0">{coach?.step2}</p>
            </div>
            <div className="d-flex flex-column">
              <small>Erfahrung</small>
              <p className="mb-0">{coach?.step3}</p>
            </div>
            <div className="d-flex flex-column">
              <small>Praxiserfahrung</small>
              <p className="mb-0">{coach?.step4}</p>
            </div>
          </CouchStats>
          <Actions className="gap-3 d-flex mt-10">
            {/* <a
            className="btn-primary text-decoration-none"
            href={`${coach?.pp_first_meeting_calendly_url_profilpage}${APPEND_CALENDLY_URL}`}
          >
            Erstgespräch vereinbaren
          </a> */}
            <PopupButton
              className="ct-link-text foodiary-green-button text-decoration-none"
              url={`${coach?.pp_first_meeting_calendly_url_profilpage}${APPEND_CALENDLY_URL}`}
              rootElement={document.getElementById("root")}
              text="Erstgespräch vereinbaren"
            />
            <button
              className="ct-link-text foodiary-outline-button"
              onClick={() => onPaymentModal()}
            >
              Coaching buchen
            </button>
          </Actions>
      </CouchContent>
      <CouchProfile className="d-flex flex-column">
        <div className="gap-3 d-flex flex-column align-items-center">
          <img src={imgUrl} alt="user" />
          <IconActions className="d-flex align-items-center">
            <a target="_blank" rel="noreferrer" href={coach.instagram_url}>
              <img src={InstagramIcon} alt="user" />
            </a>
            <a href={`mailto:${coach.email}`}>
              <img src={MailIcon} alt="user" />
            </a>
            <a target="_blank" rel="noreferrer" href={"#"}>
              <img src={ShareIcon} alt="user" />
            </a>
          </IconActions>
        </div>
      </CouchProfile>
    </Card>
  )
}

export default CouchInfo
