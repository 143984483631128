import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather"
// Components
import CoachCard from "components/CoachCard/CoachCard"
import * as CONSTANT from "constants"

// Redux
import * as CoachActions from "redux/coach/coach.actions"

const CoachList = (props) => {
  const [isMobile, setIsMobile] = useState(false)
  const [couchListActivePage, setCouchListActivePage] = useState("")

  const coachList = useSelector((state) => state.coach.coachList)
  const totalPages = Math.ceil(coachList?.total / coachList?.per_page) + 2

  const dispatch = useDispatch()

  useEffect(() => {
      window.scrollTo(0, 0)
      window.addEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
}, [coachList])

  useEffect(() => {
      getCouches()
  }, [dispatch])

  const getCouches = (pageUrl = "") => {
      const URL = pageUrl || `coach/list?page=1`
      setCouchListActivePage(URL)
      dispatch(
          CoachActions.coachListRequest({
              formData: { url: URL },
              callback: () => null,
          })
      )
  }

  const paginationItems = () => {
      let pages = Array.from({ length: totalPages }, (_, idx) => idx)
      return pages.map((page, i) => {
          if (i === 0 && coachList?.prev_page_url) {
              return (
                  <li className="page-item" key={page}>
                      <ArrowLeftCircle
                          strokeWidth={1}
                          onClick={() => {
                              getCouches(`${coachList?.prev_page_url.split('api/')[1]}`)
                              setCouchListActivePage(coachList?.prev_page_url.split('api/')[1])
                          }}
                      />
                  </li>
              )
          }
          if (i === pages.length - 1 && coachList?.next_page_url) {
              return (
                  <li className="page-item" key={page}>
                      <ArrowRightCircle
                          strokeWidth={1}
                          onClick={() => {
                              getCouches(`${coachList?.next_page_url.split('api/')[1]}`)
                              setCouchListActivePage(coachList?.next_page_url.split('api/')[1])
                          }}
                      />
                  </li>
              )
          }
          if (i !== 0 && i !== pages.length - 1) {
              return (
                  <li className="page-item" key={page}>
                      <span
                          className={
                              `coach/list?page=${page}` === couchListActivePage
                                  ? "page-link active-page"
                                  : "page-link"
                          }
                          onClick={() => {
                              getCouches(`coach/list?page=${page}`)
                              setCouchListActivePage(`coach/list?page=${page}`)
                          }}
                      >
                          {page}
                      </span>
                  </li>
              )
          }
      })
  }

  const handleResize = () => {
      if (window.innerWidth < 600) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
  }

  return (
    <>
      <section className="grid-view cards-view">
        {coachList?.data.map((item) => (
          <CoachCard
            isMobile={isMobile}
            coach={item}
            key={item?.id}
            baseUrl={CONSTANT.IMG_BASE_URL}
            learnMoreLink={item?.pp_first_meeting_calendly_url_profilpage}
          />
        ))}
      </section>
      <div className="load-more-action text-center">
        <nav className="pagination-nav">
          <ul className="pagination">{coachList && paginationItems()}</ul>
        </nav>
      </div>
    </>
  )
}

export default CoachList
