import React from "react";
import Chip from "components/general/formComponents/chip/Chip";
import ReactPlayer from "react-player";
import * as C from "./nutritionCoach.styled";

// Images
// import PersonImage from "assets/images/Page3/Foodiary-Coaching-mobile.svg";

const NutritionCoach = () => {
  return (
    <C.GeneralWrap>
      <C.TextContent>
        <Chip text="COACHES" margin="0 0 3rem 0" />
        <h3 className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Kein Abnehmprogramm, sondern eine Ernährungsumstellung
        </h3>
        <p className="ct-text-block color-paragraph font-normal text-base">
          Dein Foodiary Coach hilft dir in 3 - Monaten dabei, deine
          Ernährungsumstellung in der Praxis erfolgreich durchzuführen. Mit
          seiner Hilfe wirst du Step by Step dein Ziel erreichen und zugleich
          vermittelt er dir das gesamte Wissen, welches du in der Zukunft
          benötigst, um selbst zum Experten zu werden.
        </p>
        <p className="ct-text-block color-paragraph-alt font-normal text-base mt-4">
          Wähle jetzt deinen Foodiary Coach aus und starte in ein gesundes Leben
          voller Energie und Selbstbewusstsein.
        </p>
        <a
          id="link_text-353-33"
          class="ct-link-text foodiary-green-button mt-8"
          href="https://begin.foodiary.app/coaches"
          rel="noreferrer"
        >
          Coach finden
        </a>
      </C.TextContent>
      <C.VisualContent>
        <ReactPlayer
          url={
            "https://foodiary.app/data/Coach-auswahl-foodiary-coachingpro-v2.mp4"
          }
          playsinline
          playing
          muted
          loop
        />
        {/* <img src={PersonImage} alt="coach" /> */}
      </C.VisualContent>
    </C.GeneralWrap>
  );
};

export default NutritionCoach;
