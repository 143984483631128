import styled from "styled-components"

export const Wrapper = styled.div`
  background: rgb(238 238 238);
  border-top-left-radius: 6.5rem;
  border-top-right-radius: 6.5rem;
  padding: 96px 0;
  
  @media (max-width: 600px) {
    padding: 83.5px 30px;
    border-top-left-radius: 3.5rem;
  border-top-right-radius: 3.5rem;
  }
`

export const Content = styled.div`

`

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 40px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 20px;
  } */
`

export const Description = styled.p`
  color: #44554c;
  /* font-size: 18px;
  font-weight: 400;
  margin: 40px 0 40px;
*/
  @media (max-width: 600px) {
    br {
      display: none;
    }
  } 
`
