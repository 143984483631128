import { useState } from "react";
import ReactPlayer from "react-player";
import { PopupButton } from "react-calendly";
import {
  Section,
  TextContent,
  VisualContent,
  Wrapper,
} from "./freeInitialSupport.style";
import VideoBg from "assets/images/video-background.svg";
// import Video from "assets/videos/CoachingErstgespraech.mp4";
import PlayIcon from "assets/images/play-outline-filled.svg";
import * as CONST from "constants";

const FreeInitialSupport = ({ coach, baseUrl }) => {
  const [IsPlay, setIsPlay] = useState(false);

  return (
    <Section>
      {/* <Background background={GreenBg} /> */}
      <Wrapper className="d-grid">
        <VisualContent>
          {coach?.pp_Intoduction_video_profilpage && (
            <>
              <img className="video-bg" src={VideoBg} alt="video" />
              <div className="video">
                <ReactPlayer
                  controls={false}
                  url={`${CONST.IMG_BASE_URL}${coach?.pp_Intoduction_video_profilpage}`}
                  playing={IsPlay}
                  onEnded={() => setIsPlay(false)}
                />
                {!IsPlay && (
                  <img
                    className="play-btn cursor-pointer"
                    src={PlayIcon}
                    alt="play"
                    onClick={() => setIsPlay(true)}
                  />
                )}
              </div>
            </>
          )}
          {!coach?.pp_Intoduction_video_profilpage && (
            <div className="video unset">
              <ReactPlayer
                muted={true}
                playing
                loop
                playsinline
                controls={false}
                url={"https://foodiary.app/data/Coaching-Erstgespraech.mp4"}
              />
            </div>
          )}
        </VisualContent>
        <TextContent>
          <h3 className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
            <strong>
              Ich unterstütze dich mit meinem <br /> kostenlosen Erstgespräch
            </strong>
          </h3>
          <p className="ct-text-block color-paragraph font-normal text-base mb-12">
            {coach?.pp_Intoduction_text_profilpage}
          </p>
          <br />
          <PopupButton
            className="ct-link-text foodiary-outline-button"
            url={`${coach?.pp_first_meeting_calendly_url_profilpage}${CONST.APPEND_CALENDLY_URL}`}
            rootElement={document.getElementById("root")}
            text="Erstgespräch vereinbaren"
          />
        </TextContent>
      </Wrapper>
    </Section>
  );
};

export default FreeInitialSupport;
