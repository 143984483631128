import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import Chip from "components/general/formComponents/chip/Chip";
import * as F from "./findPersonalCoach.style";
// Redux
import * as CoachActions from "redux/coach/coach.actions";

import DefaultUserProfile from "assets/outer-pages/png/default.png";

const FindPersonalCoach = () => {
  const commissionedPromotedCoaches = useSelector(
    (state) => state.coach.commissionedPromotedCoaches
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      CoachActions.commissionedPromotedCoachListRequest({
        callback: () => null,
      })
    );
  }, [dispatch]);

  return (
    <F.Wrap>
      <Chip text="COACHES" margin="0 auto 3rem" />
      <F.SectionTitle className="ct-headline foodiary-h2 color-dark text-4xl mt-8">
        <b className="font-semibold">Finde jetzt deinen persönlichen Coach</b>
      </F.SectionTitle>
      <p
        id="text_block-222-9"
        className="ct-text-block color-paragraph font-normal text-base mt-10 text-center mx-auto"
      >
        Wir sind ein Team aus Ernährungsberatern, Ernährungswissenschaftlern und
        Sportwissenschaftlern, welches für dich deinen persönlichen
        Ernährungsplan erstellt und dich bei der dauerhaften
        Ernährungsumstellung tatkräftig unterstützt.
      </p>
      {/* <F.MobileText className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-light">
        SO EINFACH FUNKTIONIERT'S BUCHE <br /> <b className="font-semibold">JETZT DEIN COACHING PRO</b>
      </F.MobileText> */}
      <div
        id="div_block-223-9"
        class="ct-div-block w-full mt-20 grid items-stretch grid-cols-4 lg-grid-cols-2 gap-8"
      >
        {commissionedPromotedCoaches?.promotedCoachList
          ?.slice(0, 4)
          .map((item) => (
            <a
              class="ct-link bg-white shadow-md rounded-xl hover-float flex items-center overflow-hidden p-8"
              href="https://mycoach.foodiary.app/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                id="image-233-9"
                alt="Chrysanthi-Foodiary-Ernährungsberater-white"
                src={
                  item?.profilePic
                    ? getS3ImageUrl(item?.profilePic)
                    : DefaultUserProfile
                }
                class="ct-image h-auto promoted-coach-img"
              />
              <div
                id="text_block-226-9"
                class="ct-text-block text-lg font-medium color-dark pb-4 pt-6"
              >
                {item?.prename} {item?.surname}
              </div>
              <div
                id="text_block-227-9"
                class="ct-text-block font-normal text-base text-center uppercase color-paragraph-alt"
              >
                Foodiary Coach
              </div>
            </a>
          ))}
      </div>
    </F.Wrap>
  );
};

export default FindPersonalCoach;
