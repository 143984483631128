import styled from "styled-components";

export const InformationHeading = styled.div`
  padding-top: 45px;
  color: #142c1f;
  font-size: 22px;
  margin-bottom: 30px;
`;

export const InformationDescription = styled.div`
  font-size: 16px;
  font-family: "Rubik-Regular";
  color: #44554c;
  margin: 0 0 40px 0;
`;

export const Header = styled.header`
  /* background: transparent linear-gradient(90deg, #e5f5f2 0%, #edfbf6 100%); */
  background: #E8F6F4;
`;

export const HeaderInner = styled.div`
  padding-top: 3.4rem;
`;
