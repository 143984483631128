import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
`;

export const TextContent = styled.div`
  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const Title = styled.h3``;
