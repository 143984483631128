import React from "react";
import { PopupButton } from "react-calendly";
import * as F from "./readyForNewAttitude.style";
// Images
import IconOne from "assets/images/partnership/Icon-Erstgespraech.svg";
import IconTwo from "assets/images/partnership/Icon-coaching-pro.svg";
import ArrowIcon from "assets/images/partnership/icon-arrow.svg";

const ReadyForNewAttitude = () => {
  return (
    <F.Wrap>
      <F.SectionTitle className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-light">
        BIST DU BEREIT FÜR EIN NEUES LEBENSGEFÜHL? <br />
        <b className="font-semibold">DANN STARTE JETZT MIT FOODIARY!</b>
      </F.SectionTitle>
      <F.MobileText className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-light">
        SO EINFACH FUNKTIONIERT'S BUCHE <br /> <b className="font-semibold">JETZT DEIN COACHING PRO</b>
      </F.MobileText>
      <F.CardsWrap>
        <F.ConsultationBox>
          <img src={IconOne} alt="Icon" />
          <h4 className="color-dark text-2xl">KOSTENLOSES ERSTGESPRÄCH</h4>
          <p className="color-dark text-xl text-center">
            Wähle deinen Coach und lerne diesen während dem 45-minütigen
            Erstgespräch kennen.
          </p>
        </F.ConsultationBox>
        <F.ConsultationBoxIcon>
          <img src={ArrowIcon} alt="Left Arrow" />
        </F.ConsultationBoxIcon>
        <F.ConsultationBox>
          <img src={IconTwo} alt="Icon" />
          <h4 className="color-dark text-2xl">FOODIARY COACHING PRO</h4>
          <p className="color-dark text-xl text-center">
            Starte gemeinsam mit deinem Coach deine Ernährungsumstellung und
            erreiche dein Ziel.
          </p>
        </F.ConsultationBox>
      </F.CardsWrap>
      {/* <PopupButton
        className="btn-primary ct-link-text foodiary-green-button cursor-pointer mt-12 d-block mx-auto"
        url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
        rootElement={document.getElementById("root")}
        text="Coach finden"
      /> */}
    </F.Wrap>
  );
};

export default ReadyForNewAttitude;
