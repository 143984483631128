import styled from "styled-components";

export const RightCoachContainer = styled.div``;

export const Title = styled.h3``;

export const TwoColumsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;

  @media (max-width: 61.9375rem) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;


export const TwoColums = styled.ul`
  list-style: none;
  padding-left: 0rem;
  margin: 0;
  /* columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;

  @media (max-width: 61.9375rem) {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  } */

  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 3.875rem;

    span {
      color: #142c1f;
      padding-top: 0.625rem;

      &.WidthChanges {
        width: 53px;
        height: 3.3125rem;
        padding-top: 0;
      }
    }
  }
`;
