import styled from "styled-components";

export const CardNew = styled.div`
  border-radius: 8px;
  padding: 30px 23.5px;
  background: #f0f4f3;
  cursor: pointer;
  
  @media (max-width: 600px) {
    padding: 15px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    margin: 0 auto 20px;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  > img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 10px solid #ffffff;
    margin-inline: auto;

    @media (max-width: 600px) {
      width: 110px;
      height: 110px;
    }
  }

  .chip {
    transform: translateY(-65%);
  }
`;

export const CoachTagBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
`;

export const CoachTag = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 8px 12px;

  color: #1c9d87;
  font-size: 13px;
  width: fit-content;
  @media (max-width: 600px) {
    &.step1, &.subtitle-desktop {
      display: none;
    }
  }

  @media (min-width: 601px) {
    &.more, &.subtitle-mobile {
      display: none;
    }
  }
`;
