import React from "react";
import Button from "components/general/formComponents/button/Button";
import * as F from "./coachingPlans.styled";
import * as G from "styles/General.Styles";
// Images
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CrossIcon from "assets/images/myCoaching/Icon-Cross.svg";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";

const CoachingPlans = () => {
  return (
    <F.CoachingPlansBox className="plansBox">
      <F.PlanBox className="nonActive">
        <F.PlanBoxHead>
          <F.TitleInfo>ERSTGESPRÄCH</F.TitleInfo>
          <F.Title>Kostenlos</F.Title>
          <F.SubTitle>einmalig</F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Erstgespräch
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Unterstützung beim Erstellen deines Ernährungsplanes
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Erklärung aller Funktionen der Foodiary App
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Persönlicher Coach für 3 Monate
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Foodiary PRO Funktionen
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        <a
          id="link_text-277-33"
          class="ct-link-text foodiary-outline-button mt-0"
          href="https://begin.foodiary.app/coaches"
          rel="noreferrer"
        >
          Coach finden
        </a>
      </F.PlanBox>

      <F.PlanBox className="active">
        <F.PlanBoxHead>
          <F.TitleInfo className="active">FOODIARY COACHING PRO</F.TitleInfo>
          <F.Title>150,00 €</F.Title>
          <F.SubTitle>pro Monat</F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Erstgespräch
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Unterstützung beim Erstellen deines Ernährungsplanes
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Erklärung aller Funktionen der Foodiary App
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Persönlicher Coach für 3 Monate
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Foodiary PRO Funktionen
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        <a
          id="link_text-302-33"
          class="ct-link-text foodiary-green-button mt-0"
          href="https://begin.foodiary.app/coaches"
          rel="noreferrer"
        >
          Coach finden
        </a>
      </F.PlanBox>
    </F.CoachingPlansBox>
  );
};

export default CoachingPlans;
