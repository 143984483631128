import ApiService from ".";

const BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_BASE_URL_DEVELOPMENT;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_BASE_URL_STAGING;
  else if (process.env.REACT_APP_ENV === "production") {
    return process.env.REACT_APP_BASE_URL_PRODUCTION;
  }
};

const FILE_BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FILES_STAGING_BASE_URL;
  else if (process.env.REACT_APP_ENV === "production")
    return process.env.REACT_APP_FILES_PRODUCTION_BASE_URL;
};

const FILE_BASE_URL_S3 = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL_S3;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FILES_STAGING_BASE_URL_S3;
  else if (process.env.REACT_APP_ENV === "production")
    return process.env.REACT_APP_FILES_PRODUCTION_BASE_URL_S3;
};

const coach = new ApiService({ baseURL: BASE_URL(), loginType: "trainer" });

const ApiCollections = {
  // *********** Coach Module ********
  getCoachList: (params) => coach.get(params.url),
  getCommissionedPromotedCoaches: () =>
    coach.get("commision_model/promoted-coach/list"),
  getPromotedCoachList: () => coach.get("promoted-coach/list"),
  getTrainerID: (params) => coach.imgPost("coach_website_url/trainer", params),
  getCoachFeedbacks: () => coach.imgPost("coach/feedback/all"),
  getCoachData: (params) => coach.imgPost("coach/data", params),
};

export { FILE_BASE_URL, FILE_BASE_URL_S3 };
export default ApiCollections;
