import styled from "styled-components"

const FiltersNavbar = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  justify-content: space-between;
  gap: 2rem;
  padding-block: 30px;
  margin-top: 1.5rem;

  * {
    font-family: 'RubikRegular';
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 0.5rem;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    gap: 0.5rem;
    .left {
      align-self: flex-start;
    }

    button {
      width: 100%;
    }
  }
`

export { FiltersNavbar }
