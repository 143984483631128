import styled from "styled-components"

const Card = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  gap: 2rem;
  @media (max-width: 991.99px) {
    flex-direction: column-reverse;
  }
`

const CouchProfile = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 44%;
  align-items: end;
  img {
    width: 43rem;
    height: 43rem;
    box-shadow: 0px 50px 99px #02332a1a;
    border-radius: 50px;
    object-fit: cover;
    border: 2rem solid white;
    background: white;
  }

  @media (max-width: 991.99px) {
    width: 100%;
    align-items: center;
  }

  @media (max-width: 767.99px) {
    img {
      width: 30rem;
      height: 30rem;
    }
  }

  @media (max-width: 576px) {
  }
`
const IconActions = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  box-shadow: 0px 0px 20px #d6d6d6a2;
  border-radius: 20px;
  padding: 1.5rem 2rem;
  width: fit-content;
  background: white;
  margin-top: -5.2rem;
  gap: 1.7rem;
  a {
    width: 3rem;
    height: 3rem;
  }
  img {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 0 !important;
    object-fit: contain !important;
    border: unset !important;
    cursor: pointer;
    box-shadow: none !important;

    &:nth-child(odd) {
      height: 1.34rem;
    }
  }
`

const CouchContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  /* padding-top: 3rem; */
  width: 56%;
  .back-link {
    color: #a5b5af;
    /* font-size: 18px; */
    cursor: pointer;
  }
  h2 {
    color: #142c1f;
    margin-top: 2.15rem;
    margin-bottom: 0.5rem;
    /* font-weight: 600;
    font-size: 40px; */
  }

  small {
    /* color: #a5b5af; */
    color: #2ecf96;
    font-size: 2rem;
    margin-top: 0.5rem;

    @media (max-width: 600px) {
      display: block;
    }
  }

  @media (max-width: 767.99px) {
    h2 {
      /* font-size: 26px; */
      margin-top: 1.15rem;
    }
    .back-link {
      /* font-size: 14px; */
    }
  }

  @media (max-width: 991.99px) {
    width: 100%;
    margin-top: 2rem;
  }
`

const CouchStats = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding: 1rem 2rem 1rem 3rem;
  /* box-shadow: 0px 0px 40px #142c1f14; */
  word-break: break-all;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: white;
  box-shadow: 0px 5px 20px #02332a1a;
  border-radius: 8px;

  div {
    /* border-right: 1px solid #eeeeee; */
    justify-content: center;
    align-items: self-start;
    padding: 1rem;
    position: relative;
    &:before {
      content: "";
      width: 1px;
      height: 66%;
      background: #eeeeee;
      top: 17%;
      position: absolute;
      left: 0;
    }

    &:first-child:before {
      background: #2ecf96;
    }

    small {
      /* color: #a5b5af; */
      color: #44554c;
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin-top: 0;
    }

    p {
      margin-top: 1.4rem;
      color: #44554c;
      font-size: 1.8rem;
      line-height: 1.6rem;
      font-weight: 500;
      word-break: break-word;
      /* text-align: center; */
    }

    &:last-child {
      border-right: none;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;

    div {
      &:first-child,
      &:nth-child(2) {
        /* border-bottom: 1px solid #eeeeee; */
      }

      &:nth-child(2) {
        border-right: none;
      }
    }
  }
`

const CouchItem = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-top: 2.5rem;
  .coach-icon {
    background: white;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    margin-right: 20px;
    box-shadow: 1px 1px 7px 3px #aca9a936;
  }
  img {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
  }

  div {
    small {
      color: #a5b5af;
      font-size: 2rem;
      text-align: left;
    }

    p {
      color: #142c1f;
      font-size: 2.2rem;
      font-weight: 500;
      margin-top: 0;
    }
  }

  @media (max-width: 600px) {
    /* justify-content: center; */
  }
`

const Actions = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  a,
  button {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`

export {
  Card,
  CouchStats,
  CouchContent,
  CouchItem,
  Actions,
  CouchProfile,
  IconActions,
}
