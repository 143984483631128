import React from "react";
import * as F from "./rightCoach.styled";
import * as G from "styles/General.Styles";

// Images
import WhiteTick from "assets/images/Page2/Icon-check-green-white.svg";
import GreenTick from "assets/images/Page2/greenCircleCheck.svg";

const List = [
  {
    img: GreenTick,
    text: "…Unterstützung bei deiner Lebensumstellung suchst",
  },
  {
    img: GreenTick,
    text: "…dich im Alltag gesund und flexibel ernähren möchtest",
  },
  {
    img: GreenTick,
    text: "…Eine Ernährungsberatung möchtest, die individuell auf dich und dein Leben abgestimmt ist",
  },
  {
    img: GreenTick,
    text: "...Tipps für eine vielfältige und gesunde Ernährung brauchst",
  },
  {
    img: GreenTick,
    text: "...bereit bist den ersten Schritt in ein neues Leben zu gehen",
  },
  {
    img: GreenTick,
    text: "...ein gut durchdachtes Konzept suchst, bei dem du keine kurzweiligen Erfolge durch Diäten und Unwahrheiten erzielst, sondern ein langfristig glückliches Lebensgefühl",
  },
];

const RightCoach = () => {
  return (
    <F.RightCoachContainer className="ct-div-block shadow-xl rounded-xl py-20 px-12 centered">
      <F.Title className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12 uppercase font-semibold">
        Ein Foodiary Coach ist richtig für dich wenn, du
      </F.Title>

      <F.TwoColumsGrid>
        <F.TwoColums>
          {List.slice(0, 3).map((item) => (
            <li>
              <G.PlanBoxIconBox className="WidthChanges">
                <img src={item.img} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block foodiary-list-item text-xl color-dark text-left font-normal">
                {item.text}
              </G.PlanBoxInformation>
            </li>
          ))}
        </F.TwoColums>

        <F.TwoColums>
          {List.slice(3).map((item) => (
            <li>
              <G.PlanBoxIconBox className="WidthChanges">
                <img src={item.img} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block foodiary-list-item text-xl color-dark text-left font-normal">
                {item.text}
              </G.PlanBoxInformation>
            </li>
          ))}
        </F.TwoColums>
      </F.TwoColumsGrid>
    </F.RightCoachContainer>
  );
};

export default RightCoach;
