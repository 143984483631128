import styled from "styled-components"

export const Navbar = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 78px;
  width: 100%;
  /* max-width: 1270px; */
  max-width: 1366px;
  background: white;
  z-index: 19;
  /* transition: all 0.34s ease; */

  ${({ isSticky }) =>
    isSticky &&
    `
    position: fixed;
    top: 10px;
    border-radius: 20px;
    padding: 0 16px;
    width: calc(100% - 110px);
    max-width: 1230px;
    box-shadow: 0px 5px 10px #142C1F14;
    box-sizing: border-box;

    @media (max-width: 767.99px){
      width: calc(100% - 30px);
      left: 15px;
    }
  `}
`

export const Logo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 8rem;
  margin-left: -10px;

  @media (max-width: 600px) {
    width: 6rem;
  }
`
export const NavItems = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;

  li {
    a {
      padding: 1rem;
       
      color: #44554c;
      cursor: pointer;
      font-size: 16px;
      text-decoration: none;
      display: block;

      &:hover,
      &:focus,
      &:active {
        color: #142c1f;
      }

      &.active-nav-link::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 30px;
        border-bottom: 4px solid #2ecf96;
        border-radius: 4px;
        position: relative;
        top: 15px;
      }
    }
  }

  @media (max-width: 991.99px) {
    display: none;
  }
`

export const MobileNavItems = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0;

  li {
    img {
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
  }

  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: 600px) {
    li {
      button {
        padding: 0.8rem 1rem;
      }
    }
  }
`

export const MobileNavbar = styled.div`
  position: fixed;
  top: 15px;
  left: 5px;
  width: calc(100% - 10px);
  background: white;
  z-index: 899;
  border-radius: 20px;
  padding: 0 15px;
  /* box-shadow: 0px 5px 10px #142c1f14; */
  /* transition: all 0.34s ease; */
  box-shadow: 0 -1px 9px rgb(20 45 82 / 3%), 0 0 1px rgb(20 45 82 / 1%),
    0 1px 1px rgb(20 45 82 / 1%), 0 1px 2px rgb(20 45 82 / 2%),
    0 3px 4px rgb(20 45 82 / 3%), 0 5px 8px rgb(20 45 82 / 4%),
    0 9px 14px rgb(20 45 82 / 5%);
    /* transform: translate3d(-50%, 0, 0.1px); */
  @media (max-width: 600px) {
    height: calc(100vh - 40px);
  }
`

export const NavbarItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 600px) {
    height: calc(100vh - 300px);
  }
`

export const MobileNavbarItems = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    a {
      padding: 0 0 16px 0;
       
      color: #142c1f;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;
      display: block;

      &:hover,
      &:focus,
      &:active {
        color: #142c1f;
      }

      &.active-nav-link {
        color: #1c9d87;
      }
    }
  }
`

export const Header = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 100%;
  margin: 16px 0 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MobileNavbarLogo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 8rem;
  margin-left: -10px;
`

export const Close = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 30px;
  height: 30px;
  cursor: pointer;
`

export const Divider = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 100%;
  background: #dddddd;
  margin: 1rem 0 2rem;
  height: 1px;
`

export const AppDownloadItem = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  font-size: 16px;
  font-weight: 600;
  color: #1c9d87;
  margin-bottom: 16px;
   
  display: flex;
  align-items: center;
  gap: 16px;
  img {
    width: 16px;
    height: 22px;
  }
`

export const LoginItem = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  font-size: 16px;
  font-weight: 600;
   
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 16px;
    height: 16px;
  }
`
