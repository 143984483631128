import React from "react"
import { PopupButton } from "react-calendly"
import * as C from "./nutritionCoachNew.styled"
// Images
import PersonImage from "assets/images/Page3/Foodiary-Coaching-mobile.svg"

const NutritionCoach = ({ onPaymentModal }) => {
  return (
    <C.GeneralWrap>
      <C.TextContent>
        <C.Title>
          Ein eigener Ernährungscoach ist der Schlüssel zum Erfolg!
        </C.Title>
        <C.Description>
          Konzentriere dich während deiner Ernährungsumstellung voll und ganz
          auf die Umsetzung und überlasse deinem Coach die lästige Arbeit. Du
          bist dir nicht sicher, ob du das Rezept essen darfst, was du machen
          sollst, wenn dein Gewicht stagniert oder ein Geburtstag ansteht? –
          Kein Problem! Dein Coach ist immer für dich da und übernimmt gern die
          Aufgabe.
        </C.Description>
        <PopupButton
          className="btn-primary custum-btn"
          url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
          rootElement={document.getElementById("root")}
          text="Termin vereinbaren"
        />
      </C.TextContent>
      <C.VisualContent>
        <img src={PersonImage} alt="coach" />
      </C.VisualContent>
    </C.GeneralWrap>
  )
}

export default NutritionCoach
