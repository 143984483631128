import React from "react";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import ReactPlayer from "react-player";
import * as C from "./freeInitialConsultation.style";

import Chip from "components/general/formComponents/chip/Chip";

const FreeInitialConsultation = () => {
  const coach = useSelector((state) => state.coach.coach);

  return (
    <C.Wrap>
      <C.VisualContent>
        <ReactPlayer
          url={"https://foodiary.app/data/Coach-waehlen-Foodiarymp4-2.mp4"}
          playsinline
          playing
          muted
          loop
        />
      </C.VisualContent>
      <C.ContentSection>
        <Chip text="ERSTGESPRÄCH" margin="0 0 3rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Dein kostenloses Erstgespräch mit unschlagbarem Mehrwert
        </C.Title>
        <C.Description className="ct-text-block color-paragraph text-base text-left">
          In einem 45-Minütigem Video Call errechnen wir deinen persönlichen
          Kalorienbedarf. Wir bestimmen gemeinsam deine Ernährungsform und
          achten hierbei auf eine ausgewogene Ernährung. Du lernst deinen
          Foodiary Coach kennen und er beantwortet dir alle Fragen rund um das
          Thema Ernährungsumstellung.
        </C.Description>
        <C.Action>
          <PopupButton
            className="ct-link-text foodiary-green-button cursor-pointer mt-12"
            url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
            rootElement={document.getElementById("root")}
            text="Termin buchen"
          />
        </C.Action>
      </C.ContentSection>
    </C.Wrap>
  );
};

export default FreeInitialConsultation;
