import React from "react";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";

import * as F from "./InitialConsultationBox.style";

const InitialConsultationBox = () => {
  const coach = useSelector((state) => state.coach.coach);

  return (
    <F.Wrap>
      <F.Content>
        <F.Title className="ct-text-block color-dark text-3xl font-bold">
          Ernährungsplan erstellen
        </F.Title>
        <F.Description className="ct-text-block text-base color-paragraph font-normal mt-12 mb-12 text-center">
          Dein persönlicher Ernährungsberater sorgt für Klarheit im wirren
          Diätenwahnsinn. Er steht auf deinem Weg zu einem gesünderem Leben an
          deiner Seite.
        </F.Description>
        {/* <a
          id="link_text-61-15"
          class="ct-link-text foodiary-green-button hover-float mx-auto"
          href="https://mycoach.foodiary.app"
          target="_blank"
          rel="noreferrer"
        >
          Erstgespräch vereinbaren
        </a> */}

        <PopupButton
          className="ct-link-text foodiary-green-button cursor-pointer mx-auto"
          url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
          rootElement={document.getElementById("root")}
          text="Erstgespräch vereinbaren"
        />
      </F.Content>
    </F.Wrap>
  );
};

export default InitialConsultationBox;
