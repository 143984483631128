import React, { useState } from "react"
import Chip from "components/general/formComponents/chip/Chip"
import Accordian from "components/Accordians/Accordian"
import * as C from "./FirstMeeting.styled"
// Images
import Ernahrungsberatung from "assets/images/partnership/1/Ernahrungsberatung-foodiary.png"
import Basic from "assets/images/partnership/1/Foodiary-Basic.png"
import Ansprechpartner from "assets/images/partnership/1/Foodiary-Ansprechpartner.png"
import Ernaehrungsplan from "assets/images/partnership/1/Foodiary-eigener-Ernaehrungsplan.png"
import TippsTricks from "assets/images/partnership/1/Foodiary-Tipps-Tricks.png"
import HealthScore from "assets/images/partnership/1/health-score.png"

const Questions = [
  {
    id: 1,
    img: Ernahrungsberatung,
    title: "Persönliches Erstgespräch",
    info: "Mit dem kostenlosem Erstgespräch erhältst du eine 45-minütige Ernährungsberatung.",
  },
  {
    id: 2,
    img: Ernaehrungsplan,
    title: "Eigener Ernährungsplan",
    info: "Über 1700 Rezepte werden dank künstlicher Intelligenz optimal auf dein Ernährungsplan angepasst. Du Entscheidest ganz nach deinen Wünschen, welches Rezept du zubereiten möchtest.",
  },
  {
    id: 3,
    img: Basic,
    title: "Einrichtung Foodiary BASIC",
    info: `Gemeinsam mit einem 
    Foodiary Coach 
    erstellst du deinen 
    persönlichen 
    Ernährungsplan. 
    Diesen kannst du 
    direkt in unserer App 
    verwenden.`,
  },
  {
    id: 4,
    img: HealthScore,
    title: "Health Score",
    info: `In einer Anamnese 
    analysieren wir deinen 
    IST-Zustand und werten 
    diesen für dich aus.`,
  },
  {
    id: 5,
    img: TippsTricks,
    title: "Tipps & Tricks",
    info: `Du erhältst wertvolle 
    Tipps und Tricks, wie du 
    einen Alltag gesünder 
    gestalten kannst.`,
  },
  {
    id: 6,
    img: Ansprechpartner,
    title: "Foodiary Ansprechpartner",
    info: `Unser Support steht dir 
    natürlich für Rückfragen 
    zu deiner App zur 
    Verfügung.`,
  },
]


const FirstMeeting = () => {
  const [image, setImage] = useState(Ernahrungsberatung)
  const [activeQuestion, setActiveQuestion] = useState(null)

  const onToggle = (question) => {
    setImage(question?.img)
    setActiveQuestion(question)
  }

  return (
    <C.Wrap>
      <C.TextContent>
        <Chip text="ERSTGESPRÄCH" margin="0 0 30px 0" />
        <C.Title>Während dem Erstgespräch erwartet dich:</C.Title>
        <C.Info>
          Dein Ernährungscoach beantwortet dir deine offenen Fragen rund um die Ernährungsumstellung. Nach dem Gespräch kannst du dich ganz unverbindlich für eine dauerhafte Betreuung entscheiden.
        </C.Info>
        <Accordian data={Questions} activeQuestion={activeQuestion} onExpanded={(question) => onToggle(question)} />
      </C.TextContent>
      <C.VisualContent>
        <img src={image} alt="accord" />
      </C.VisualContent>
      
    </C.Wrap>
  )
}

export default FirstMeeting
