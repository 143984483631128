import React, { useEffect } from "react"

// Components
import Navbar from "components/Navbar/Navbar"
import HappyToStart from "components/HappyToStart/HappyToStart"
import NextSteps from "components/NextSteps/NextSteps"
import Footer from "components/Footer/Footer"
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper"
import ScheduleInitialConsultation from "components/ScheduleInitialConsultation/ScheduleInitialConsultation"

// Styles
import * as G from "styles/General.Styles"

const MyCoaching = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <G.Container>
        <Navbar />
        <SectionWrapper margin="150px 0 0">
          <HappyToStart />
        </SectionWrapper>
        <SectionWrapper margin="150px 0 0px">
          <ScheduleInitialConsultation page='success' />
        </SectionWrapper>
        <SectionWrapper margin="100px 0">
          <NextSteps />
        </SectionWrapper>
      </G.Container>
      <Footer />
    </>
  )
}

export default MyCoaching
