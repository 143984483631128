import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// Components
import CoachCard from "components/CoachCard/CoachCard"
import Chip from "components/general/formComponents/chip/Chip"
import * as CONSTANT from "constants"

// Redux
import * as CoachActions from "redux/coach/coach.actions"
// Styles
import * as C from "./promotedCoachList.styles"

const PromotedCoachList = (props) => {
  const [isMobile, setIsMobile] = useState(false)

  const promotedCoaches = useSelector((state) => state.coach.promotedCoachList)
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    dispatch(
      CoachActions.promotedCoachListRequest({
        callback: () => null,
      })
    )
  }, [dispatch])

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  return (
    <C.Wrapper>
      <Chip text="COACHES" margin="0 auto 30px auto" />
      <C.Title>Unsere Coaches unterstützen dich</C.Title>
      <C.Description>
        Wir sind ein Team aus Ernährungsberatern, Ernährungswissenschaftlern und
        Sportwissenschaftlern, welches für dich deinen persönlichen <br />
        Ernährungsplan erstellt und dich bei der dauerhaften
        Ernährungsumstellung tatkräftig unterstützt.
      </C.Description>
      <section className="grid-view cards-view">
        {promotedCoaches?.promotedCoachList.slice(0, 4).map((item) => (
          <CoachCard
            isMobile={isMobile}
            coach={item}
            key={item?.id}
            baseUrl={CONSTANT.IMG_BASE_URL}
            learnMoreLink="https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round"
          />
        ))}
      </section>
    </C.Wrapper>
  )
}

export default PromotedCoachList
