import * as F from "./comprehensiveSupport.style"
import Icon from "assets/outer-pages/svg/Icon-Ernährung-Foodiary-dark.svg"
import Device from "assets/images/Coaching-pro-offer.jpg"
import Ernahrungsplan from "assets/images/ComprehensiveSupport/1-Ernahrungsplan.svg"
import Ernahrungsplane from "assets/images/ComprehensiveSupport/2-EigenerErnahrungscoach.svg"
import ErnahrungsplanerApp from "assets/images/ComprehensiveSupport/3-ErnahrungsplanerApp.svg"
import Rezepte from "assets/images/ComprehensiveSupport/4-rezepte.svg"
import CoachingArea from "assets/images/ComprehensiveSupport/5-Coaching-Area.svg"
import Erfolgsuberprufung from "assets/images/ComprehensiveSupport/6-Erfolgsuberprufung.svg"

const Items = [
  {
    id: 1,
    title: "Persönlicher Ernährungsplan",
    description: "Gemeinsam ermitteln wir deinen Kalorienbedarf, ermitteln die optimale Ernährungsform und passen dies auf deinen Tagesablauf an.",
    icon: Ernahrungsplan,
    iconHover: "",
  },
  {
    id: 2,
    title: "Eigener Ernährungscoach",
    description: "Deine Ernährungsumstellung wird von einem professionellen Ernährungscoach begleitet. Dieser steht fest an deiner Seite und unterstütz dich tatkräftig.",
    icon: Ernahrungsplane,
    iconHover: "",
  },
  {
    id: 3,
    title: "Moderene Ernährungsplaner-App",
    description: "Mit der App kannst du nicht nur deine Woche vorplanen und eine Einkaufsliste erstellen, sondern auch deine tägliche Kalorienzufuhr überprüfen.",
    icon: ErnahrungsplanerApp,
    iconHover: "",
  },
  {
    id: 4,
    title: "Über 1700 Rezepte",
    description: "Eine große Auswahl an Rezepten erleichtert dir die tägliche Planung. Deine Lieblingsrezepte kannst du einfach selbst erstellen.",
    icon: Rezepte,
    iconHover: "",
  },
  {
    id: 5,
    title: "Foodiary Coaching Bereich",
    description: "Damit deine Ernährungsumstellung ein dauerhafter Erfolg bleibt, erhältst du von uns in der Foodiary Academy über 15 Lernvideos und E-Books.",
    icon: CoachingArea,
    iconHover: "",
  },
  {
    id: 6,
    title: "Wöchentliche Erfolgsüberprüfung",
    description: "In einer wöchentlichen Erfolgskontrolle überprüfen wir deinen Fortschritt. Dein Ernährungscoach analysiert diesen und greift bei Auffälligkeiten ein.",
    icon: Erfolgsuberprufung,
    iconHover: "",
  },
]

const ComprehensiveSupport = () => {
  return (
    <F.Section>
      <div className="">
        <h2 className="ct-text-block font-light color-dark text-4xl mt-0 mb-20 text-center">
          <strong className="font-semibold">MIT MEINEM FOODIARY COACHING </strong> <br />
          ERHÄLTST DU UMFASSENDE BETREUUNG
        </h2>
        {/* <p className="sub-heading text-center">
          Gemeinsam sehen wir uns an, welche Herausforderungen du hast und was
          dich aktuell daran hindert, dein Ziel zu erreichen. <br /> Wir finden
          die optimale Strategie und werden dein Ziel erreichen.
        </p> */}
        <F.Wrapper className="wrapper d-grid">
          <img className="device" src={Device} alt="" />
          <F.Services className="services d-grid">
            {Items.map((item) => (
              <div className="service active" key={item?.id}>
                <div className="icon d-flex align-items-center justify-content-center">
                  <img src={item?.icon} alt="" />
                </div>
                <h6 className="ct-text-block font-medium color-dark text-base mt-8 mb-2">{item?.title}</h6>
                <p className="ct-text-block text-xs color-paragraph mt-2 my-0">{item?.description}</p>

                <img className="device-small" src={Device} alt="" />
              </div>
            ))}
          </F.Services>
        </F.Wrapper>
      </div>
    </F.Section>
  )
}

export default ComprehensiveSupport
