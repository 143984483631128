import styled from "styled-components"

export const SmallSelectionContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  letter-spacing: 0px;
  color: #142c1f;
  font-size: 40px;
  line-height: 60px;
  
  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 45px;
    text-align: center;
  }
`

export const SelectionWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 65px;

  @media (max-width: 800px) {
    overflow-x: scroll;
    margin-top: 40px;
    gap: 10px;
  }
`

export const SelectionBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  box-shadow: 15px 15px 30px #02332a1a;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  @media (max-width: 800px) {
    min-width: 270px;
  }

  img {
    height: 270px;
    /* min-width: 270px; */
    object-fit: cover;
  }
  span {
    margin: 30px 15px;
    font-size: 20px;
    line-height: 31px;
    color: #142c1f;
    text-decoration: none;
     
    text-align: center;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 23px;
      margin: 15px;
    }
  }
`
