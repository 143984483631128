import React, { useState } from "react"
import Accordian from "components/Accordians/Accordian"
import * as C from "./OfferAndPrice.styled"
// Images
import FoodiaryRezepte from "assets/images/pngs/Foodiary-Rezepte.png"

const Questions = [
  {
    id: 13,
    title: "Was kostet das Coaching?",
    info: `Das Coaching kostet für die gesamte Laufzeit von 3 Monate 450,00 €. Wir bieten dir jedoch 
    auch die Möglichkeit an, dies als monatliche Zahlung zu tätigen. Der monatliche Preis beträgt 
    150,00 €. Es handelt sich hierbei um kein Abo. Nach den 3 Monaten endet die Zahlung 
    automatisch.`,
  },
  {
    id: 14,
    title: "Wie kann ich kündigen?",
    info: `Es handelt sich um kein Abo. Das Coaching läuft nach 3 Monaten automatisch aus. Du musst 
    also nichts kündigen und die monatliche Zahlung endet automatisch nach 3 Monaten.`,
  },
  {
    id: 15,
    title: "Bekomme ich mein Geld zurück?",
    info: `Wir garantieren dir deinen Erfolg! Da dein Ernährungsplan individuell erstellt wird, ist ein 
    Umtausch oder eine Rückerstattung leider nicht möglich. Solltest du jedoch nach 30 Tagen, 
    keinen Erfolg haben, so profitierst du von unserer <a href="https://www.foodiary.app/agb/#erfolgsgarantie" target="_blank">Erfolgsgarantie</a> und erhältst den bezahlten 
    Betrag erstattet.`,
  },


]

const OfferAndPrice = () => {
  const [image, setImage] = useState(FoodiaryRezepte)
  const [activeQuestion, setActiveQuestion] = useState(null)

  const onToggle = (question) => {
    setImage(question?.img)
    setActiveQuestion(question)
  }

  return (
    <C.Wrap>
      <C.TextContent>
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl mb-12">Angebot & Preis:</C.Title>
        <Accordian data={Questions} activeQuestion={activeQuestion} onExpanded={(question) => onToggle(question)} />
      </C.TextContent>
    </C.Wrap>
  )
}

export default OfferAndPrice
