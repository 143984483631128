import styled from "styled-components";

export const GeneralWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  gap: 6.25rem;
  /* align-items: center;
  justify-content: center; */

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 3.125rem;
  }
`;

export const TextContent = styled.div`
  img {
    max-width: 100%;
  }

  @media (max-width: 991.99px) {
    order: 2;
  }

  @media (max-width: 600px) {
    .foodiary-green-button {
      margin-inline: auto;
    }
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  font-size: 40px;

  margin-bottom: 35px;
`;
export const Description = styled.p`
  color: #44554c;

  font-size: 18px;
  color: #7a8780;
  padding-bottom: 60px;
`;

export const VisualContent = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  > div {
    width: 100% !important;
    max-width: 600px;
    height: auto !important;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin-inline: auto;
    order: 1;
  }
`;
