import React, { useState } from "react";
import ReactPlayer from "react-player";
import * as F from "./personalNutritionCourse.style";
import Chip from "components/general/formComponents/chip/Chip";
import Weekly from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-weekly.jpg";
import Bibliothek from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-Bibliotheke.jpg";
import Academy from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-Academy.jpg";
import VideoAcademy from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-Video-Academy.jpg";
import OnlineKurs from "assets/images/functions/Coach/Small-Ernahrungsberatung-Foodiary-Online-Kurs.jpg";

import WeeklyIcon from "assets/images/functions/Coach/weeklyIcon.svg";
import BibliothekIcon from "assets/images/functions/Coach/BibliothekIcon.svg";
import AcademyIcon from "assets/images/functions/Coach/AcademyIcon.svg";
import VideoAcademyIcon from "assets/images/functions/Coach/VideoAcademyIcon.svg";
import MoreIcon from "assets/images/functions/Coach/more.svg";

const Data = [
  {
    id: 1,
    title: "Ernährungsplan",
    description: "Automatische Erstellung von Ernährungsplänen",
    image: Weekly,
    icon: WeeklyIcon,
    class: "hover-none",
  },
  {
    id: 2,
    title: "Zugang Foodiary App",
    description: "Erhalte den Zugang zur Foodiary App",
    image: Bibliothek,
    icon: BibliothekIcon,
    class: "hover-none",
  },
  {
    id: 3,
    title: "Health Score",
    description: "Wir ermitteln deine aktuelle Health Score",
    image: Academy,
    icon: AcademyIcon,
    class: "hover-none",
  },
  {
    id: 4,
    title: "Tipps & Tricks",
    description: "Wertvolle Tipps & Tricks für deinen Alltag",
    image: VideoAcademy,
    icon: VideoAcademyIcon,
    class: "hover-none",
  },
  {
    id: 5,
    title: "Fragen und Antworten",
    description: "Deine offenen Fragen werden beantwortet.",
    image: OnlineKurs,
    icon: WeeklyIcon,
    class: "hover-none",
  },
  {
    id: 6,
    title: "Und vieles mehr.",
    description: "Viele weitere Benefits während des Gespräches.",
    image: null,
    icon: MoreIcon,
    class: "hover-none",
  },
];

const PersonalNutritionCourse = () => {
  // const [SelectedImage, setSelectedImage] = useState(Weekly);
  const [SelectedItem, setSelectedItem] = useState(Data[0]);

  return (
    <F.Wrap>
      <Chip
        text="ERNÄHRUNGSBERATUNG"
        margin="0 auto 3rem"
        marginMobile="0 0 3rem"
      />
      <F.SectionTitle className="ct-text-block color-dark text-left text-4xl">
        <b>KOSTENLOSES ERSTGESPRÄCH</b>
        <br />
        DEIN PERSÖNLICHER ERNÄHRUNGSCOACH
      </F.SectionTitle>
      <F.SectionTitleMobile className="ct-text-block color-dark text-left text-4xl">
        <b>KOSTENLOSES ERSTGESPRÄCH</b> DEIN PERSÖNLICHER ERNÄHRUNGSCOACH
      </F.SectionTitleMobile>
      <F.GridContainer className="mt-12">
        {/* <img src={SelectedItem?.image} alt="icon" /> */}
        <F.VisualContent>
          <ReactPlayer
            url={
              "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/LiveChat.mp4"
            }
            playsinline
            playing
            muted
            loop
          />
        </F.VisualContent>
        <F.TextContent>
          {Data.map((item) => (
            <F.Card
              onClick={() => {
                if (!item?.image) return;
                setSelectedItem(item);
              }}
              className={item.class}
            >
              {/* SelectedItem?.id === item?.id
                  ? `selected ${item.class}`
                  : item.class */}
              <img src={item?.icon} alt="icon" />
              <h6 className="ct-text-block font-medium color-dark text-base">
                {item.title}
              </h6>
              <p className="ct-text-block text-xs color-paragraph mt-2 mb-0">
                {item.description}
              </p>

              {/* {SelectedItem?.id === item?.id && (
                <img
                  className="selected-img-on-mobile"
                  src={SelectedItem?.image}
                  alt="icon"
                />
              )} */}
            </F.Card>
          ))}
        </F.TextContent>
      </F.GridContainer>
    </F.Wrap>
  );
};

export default PersonalNutritionCourse;
