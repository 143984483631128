import CoachActionTypes from "redux/coach/coach.types";

// Trainer ID
export const trainerIdRequest = (payload) => ({
  type: CoachActionTypes.TRAINER_ID_REQUEST,
  payload,
});

export const trainerIdSuccess = (payload) => ({
  type: CoachActionTypes.TRAINER_ID_SUCCESS,
  payload,
});

export const trainerIdFailure = (error) => ({
  type: CoachActionTypes.TRAINER_ID_FAILURE,
  payload: error,
});

// Coach List
export const coachListRequest = (payload) => ({
  type: CoachActionTypes.COACH_LIST_REQUEST,
  payload,
});

export const coachListSuccess = (payload) => ({
  type: CoachActionTypes.COACH_LIST_SUCCESS,
  payload,
});

export const coachListFailure = (error) => ({
  type: CoachActionTypes.COACH_LIST_FAILURE,
  payload: error,
});

// Promoted Coach List
export const promotedCoachListRequest = (payload) => ({
  type: CoachActionTypes.PROMOTED_COACH_LIST_REQUEST,
  payload,
});

export const promotedCoachListSuccess = (payload) => ({
  type: CoachActionTypes.PROMOTED_COACH_LIST_SUCCESS,
  payload,
});

export const promotedCoachListFailure = (error) => ({
  type: CoachActionTypes.PROMOTED_COACH_LIST_FAILURE,
  payload: error,
});

// Promoted Coach List
export const commissionedPromotedCoachListRequest = (payload) => ({
  type: CoachActionTypes.COMMISSIONED_PROMOTED_COACH_LIST_REQUEST,
  payload,
});

export const commissionedPromotedCoachListSuccess = (payload) => ({
  type: CoachActionTypes.COMMISSIONED_PROMOTED_COACH_LIST_SUCCESS,
  payload,
});

export const commissionedPromotedCoachListFailure = (error) => ({
  type: CoachActionTypes.COMMISSIONED_PROMOTED_COACH_LIST_FAILURE,
  payload: error,
});

// Loading Coaches
export const loadingCoachListStart = () => ({
  type: CoachActionTypes.LOADING_PROMOTED_COACH_START,
});

export const loadingCoachListStop = () => ({
  type: CoachActionTypes.LOADING_PROMOTED_COACH_STOP,
});

// Loading Promoted Coaches
export const loadingPromotedCoachStart = () => ({
  type: CoachActionTypes.LOADING_PROMOTED_COACH_START,
});

export const loadingPromotedCoachStop = () => ({
  type: CoachActionTypes.LOADING_PROMOTED_COACH_STOP,
});

// Loading
export const loadingStart = () => ({
  type: CoachActionTypes.LOADING_START,
});

export const loadingStop = () => ({
  type: CoachActionTypes.LOADING_STOP,
});

// Coach Feedback
export const coachFeedbackRequest = (payload) => ({
  type: CoachActionTypes.COACH_FEEDBACK_REQUEST,
  payload,
});

export const coachFeedbackSuccess = (payload) => ({
  type: CoachActionTypes.COACH_FEEDBACK_SUCCESS,
  payload,
});

export const coachFeedbackFailure = (error) => ({
  type: CoachActionTypes.COACH_FEEDBACK_FAILURE,
  payload: error,
});

export const loadingCoachFeedbackStart = () => ({
  type: CoachActionTypes.LOADING_COACH_FEEDBACK_START,
});

export const loadingCoachFeedbackStop = () => ({
  type: CoachActionTypes.LOADING_COACH_FEEDBACK_STOP,
});

// Coach Data
export const coachDataRequest = (payload) => ({
  type: CoachActionTypes.COACH_DATA_REQUEST,
  payload,
});

export const coachDataSuccess = (payload) => ({
  type: CoachActionTypes.COACH_DATA_SUCCESS,
  payload,
});

export const coachDataFailure = (error) => ({
  type: CoachActionTypes.COACH_DATA_FAILURE,
  payload: error,
});
