import styled from "styled-components";

export const ContainerNavbar = styled.div`
  max-width: 161.7rem;
  /* max-width: 1390px; */
  width: 100%;
  margin-inline: auto;
  padding-inline: 15px;
  /* overflow-x: hidden; */
`;

export const Container = styled.div`
  max-width: 1340px;
  width: 100%;
  margin-inline: auto;
  padding-inline: 55px;
  /* overflow-x: hidden; */

  @media (max-width: 767px) {
    padding-inline: 15px;
  }

  /* @media (min-width: 1920px) {
    max-width: 1500px;
  } */
`;

export const GeneralWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  &.NutritionCoachWrap {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

export const GeneralLeft = styled.div`
  &.imageResponsive {
    img {
      max-width: 100%;
    }
  }
`;

export const GeneralRight = styled.div`
  img {
    width: 100%;
  }
`;

export const PlanBoxIcons = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`;

export const PlanBoxIconBox = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const PlanBoxInformation = styled.span`
  width: calc(100% - 5rem);
  padding-left: 2.5rem;
`;
