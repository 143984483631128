const CoachTypes = {
    LOADING_START: 'LOADING_START',
    LOADING_STOP: 'LOADING_STOP',

    LOADING_PROMOTED_COACH_START: 'LOADING_PROMOTED_COACH_START',
    LOADING_PROMOTED_COACH_STOP: 'LOADING_PROMOTED_COACH_STOP',

    LOADING_COACH_LIST_START: 'LOADING_COACH_LIST_START',
    LOADING_COACH_LIST_STOP: 'LOADING_COACH_LIST_STOP',

    LOADING_COACH_FEEDBACK_START: 'LOADING_COACH_FEEDBACK_START',
    LOADING_COACH_FEEDBACK_STOP: 'LOADING_COACH_FEEDBACK_STOP',
    
    TRAINER_ID_REQUEST: 'TRAINER_ID_REQUEST',
    TRAINER_ID_SUCCESS: 'TRAINER_ID_SUCCESS',
    TRAINER_ID_FAILURE: 'TRAINER_ID_FAILURE',

    COACH_LIST_REQUEST: 'COACH_LIST_REQUEST',
    COACH_LIST_SUCCESS: 'COACH_LIST_SUCCESS',
    COACH_LIST_FAILURE: 'COACH_LIST_FAILURE',

    COACH_FEEDBACK_REQUEST: 'COACH_FEEDBACK_REQUEST',
    COACH_FEEDBACK_SUCCESS: 'COACH_FEEDBACK_SUCCESS',
    COACH_FEEDBACK_FAILURE: 'COACH_FEEDBACK_FAILURE',

    PROMOTED_COACH_LIST_REQUEST: 'PROMOTED_COACH_LIST_REQUEST',
    PROMOTED_COACH_LIST_SUCCESS: 'PROMOTED_COACH_LIST_SUCCESS',
    PROMOTED_COACH_LIST_FAILURE: 'PROMOTED_COACH_LIST_FAILURE',

    COACH_DATA_REQUEST: 'COACH_DATA_REQUEST',
    COACH_DATA_SUCCESS: 'COACH_DATA_SUCCESS',
    COACH_DATA_FAILURE: 'COACH_DATA_FAILURE',

    COMMISSIONED_PROMOTED_COACH_LIST_REQUEST: 'COMMISSIONED_PROMOTED_COACH_LIST_REQUEST',
    COMMISSIONED_PROMOTED_COACH_LIST_SUCCESS: 'COMMISSIONED_PROMOTED_COACH_LIST_SUCCESS',
    COMMISSIONED_PROMOTED_COACH_LIST_FAILURE: 'COMMISSIONED_PROMOTED_COACH_LIST_FAILURE',
}

export default CoachTypes;
