import { useHistory } from "react-router-dom"
import {
  Card,
  CouchItem,
  CouchStats,
  CouchItemForMobile,
} from "./CoachCard.style"
import DefaultUserProfile from "assets/images/partnership/default.png"

const CoachCard = ({ isMobile, coach, baseUrl, learnMoreLink }) => {
  const history = useHistory()

  const imgUrl = coach?.profilePic
    ? `${baseUrl}${coach?.profilePic}`
    : DefaultUserProfile

  return (
    <Card className="text-center" key={coach?.id}>
      <img src={imgUrl} alt="" />
      <h3>
        {coach?.prename} {coach?.surname}
      </h3>
      <CouchItemForMobile>
        <p className="">{coach.subtitle}</p>
      </CouchItemForMobile>
      <CouchItem className="text-center">
        <small>Coach für</small>
        <p className="mb-0">{coach.subtitle}</p>
      </CouchItem>
      <CouchStats className="d-flex flex-wrap">
        <div className="d-flex flex-column">
          <small>Spezialgebiet</small>
          <p className="mb-0">{coach.step1}</p>
        </div>
        <div className="d-flex flex-column">
          <small>Coach seit</small>
          <p className="mb-0">{coach.step2}</p>
        </div>
        <div className="d-flex flex-column">
          <small>Ausbildungen</small>
          <p className="mb-0">{coach.step3}</p>
        </div>
        <div className="d-flex flex-column">
          <small>Praxiserfahrung</small>
          <p className="mb-0">{coach.step4}</p>
        </div>
      </CouchStats>
      <button
        className="btn-outline w-100 btn-medium"
        onClick={() => window.open(learnMoreLink, "_blank")}
      >
        Mehr erfahren
      </button>
    </Card>
  )
}

export default CoachCard
