import styled from "styled-components"

export const Wrapper = styled.div``

export const Title = styled.h3`
  font-size: 40px;
  line-height: 60px;
  
  text-align: center;
  color: #142c1f;
  margin: 0 0 50px 0;
`

export const Description = styled.p`
  font-size: 18px;
  line-height: 28px;
   
  color: #44554C;
  margin: 0 0 80px 0;
  text-align: center;
`

export const InformationDescription = styled.div`
  font-size: 16px;
   
  color: #44554c;
  margin: 0 0 40px 0;
`

export const desktopShow = styled.div`
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
`

export const mobileShow = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`
