import React, { useState, useEffect } from "react"
import * as C from "./toolsYourSuccess.styled"

// Images
import MealPlanPng1 from "assets/images/pngs/Foodiary-Rezeptauswahl.png"
import MealPlanPng2 from "assets/images/pngs/Foodiary-App-Kalorien.png"
import MealPlanPng3 from "assets/images/pngs/Foodiary-Wochenplanung.png"
import FoodIcon from "assets/images/myCoaching/Icon-food.svg"

const MealPlanTools = () => {
  const [activeCard, setActiveCard] = useState(1)
  const [image, setImage] = useState(MealPlanPng1)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    window.addEventListener("load", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("load", handleResize)
    }
  }, [])

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  const onSelectCard = (id) => {
    setActiveCard(id)
    switch (id) {
      case 1:
        setImage(MealPlanPng1)
        break
      case 2:
        setImage(MealPlanPng2)
        break
      case 3:
        setImage(MealPlanPng3)
        break
      default:
        setImage(MealPlanPng1)
        break
    }
  }

  return (
    <C.MealPlanContainer>
      <C.Title>
        <b>DEIN PERSÖNLICHER DIGITALER ASSISTENT</b> <br />
        UNTERSTÜTZT DICH BEI DEINER ERNÄHRUNGSPLANUNG
      </C.Title>
      <C.MobileTitle>
        SO EINFACH FUNKTIONIERT'S <br /> <b>BUCHE JETZT DEIN COACHING PRO</b>
      </C.MobileTitle>
      <C.MealPlanLower>
        <C.VisualContent>
          <img src={image} alt="Meal Plan" />
        </C.VisualContent>
        <C.CardBoxWrap>
          <C.CardBox
            className={activeCard === 1 && "active"}
            onClick={() => onSelectCard(1)}
          >
            <C.CardBoxIcon>
              <img src={FoodIcon} alt="Food Icon" />
            </C.CardBoxIcon>
            <C.CardBoxRight>
              <C.PlanTitle>Rezeptﬁlter mit künstlicher Intelligenz</C.PlanTitle>
              <C.PlanText>
                Dank künstlicher Intelligenz treffen wir eine Vorauswahl an
                Rezepten, welche zu deiner Ernährungsform passen und integrieren
                diese in deinen Tag.
              </C.PlanText>
              {isMobile && activeCard === 1 && (
                <C.MobileImage className="animate__animated animate__bounceIn">
                  <img src={MealPlanPng1} alt="meal 1" />
                </C.MobileImage>
              )}
            </C.CardBoxRight>
          </C.CardBox>

          <C.CardBox
            className={activeCard === 2 && "active"}
            onClick={() => onSelectCard(2)}
          >
            <C.CardBoxIcon>
              <img src={FoodIcon} alt="Food Icon" />
            </C.CardBoxIcon>
            <C.CardBoxRight>
              <C.PlanTitle>Dynamische Kalorienanpassung</C.PlanTitle>
              <C.PlanText>
                Durch die dynamische Kalorienanpassung gehen wir auf
                Veränderungen in deinem Stoffwechsel ein. Das ist für dich
                wichtig, um langfristig dein Ziel zu erreichen.
              </C.PlanText>
              {isMobile && activeCard === 2 && (
                <C.MobileImage className="animate__animated animate__bounceIn">
                  <img src={MealPlanPng2} alt="meal 1" />
                </C.MobileImage>
              )}
            </C.CardBoxRight>
          </C.CardBox>

          <C.CardBox
            className={activeCard === 3 && "active"}
            onClick={() => onSelectCard(3)}
          >
            <C.CardBoxIcon>
              <img src={FoodIcon} alt="Food Icon" />
            </C.CardBoxIcon>
            <C.CardBoxRight>
              <C.PlanTitle>Wochenplanung</C.PlanTitle>
              <C.PlanText>
                Deine Woche kannst du einfach vorplanen. So wählst du in deinem
                Ernährungsplan die Rezepte aus, welche du gerne zubereiten
                möchtest und zu deinen Makronährwerten passen. Unser künstliche
                Intelligenz ﬁltert diese so, dass du nur die Rezepte siehst,
                welche zu deinen Nährwerten passen.
              </C.PlanText>
              {isMobile && activeCard === 3 && (
                <C.MobileImage className="animate__animated animate__bounceIn">
                  <img src={MealPlanPng3} alt="meal 1" />
                </C.MobileImage>
              )}
            </C.CardBoxRight>
          </C.CardBox>
        </C.CardBoxWrap>
      </C.MealPlanLower>
    </C.MealPlanContainer>
  )
}

export default MealPlanTools
