import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})``

export const CardsWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 80px;

  @media(max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const SectionTitle = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-size: 40px;
  
  font-weight: 300;

  b {
    
    font-weight: 600;
  }

  @media(max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media(max-width: 600px) {
    display: none;
  }
`

export const SectionTitleMobile = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-size: 40px;
  
  font-weight: 300;

  b {
    
    font-weight: 600;
  }

  @media(min-width: 600.99px) {
    display: none;
  }
`
