import styled from "styled-components"

export const ShadowBackgroundWrap = styled.div`
  position: relative;
  min-height: 300px;

  @media (max-width: 600px) {
    min-height: auto;
  }
`

export const ShadowBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  /* right: 50%;
  transform: translateX(50%); */
  width: 100%;
  max-width: 600px;
  height: 100%;
  background: #1c9d87;
  mix-blend-mode: normal;
  opacity: 0.09;
  filter: blur(50px);
`

export const PageTitle = styled.h3`
  color: #142c1f;
  font-weight: 700;
  font-size: 58px;
  line-height: 90px;
  text-align: center;
  

  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 45px;
  }
`

export const PageDescription = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #44554c;
   
  margin-top: 49px;

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 23px;
  }
`
