import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Routes
import * as routes from "routes/routes";
import CustomRoute from "routes/customRoute";

// Pages
import MainCoaching from "pages/MainCoaching/MainCoaching";
import InitialConsultationOld from "pages/InitialConsultationOld/InitialConsultation";
import CoachingProOld from "pages/CoachingProOld/CoachingPro";
import CoachingProCoaching from "pages/CoachingProCoaching/CoachingProCoaching";
import CoachingProApp from "pages/CoachingProApp/CoachingProApp";
import InitialConsultation from "pages/InitialConsultation/InitialConsultation";
import CoachingPro from "pages/CoachingPro/CoachingPro";
import CoachFinder from "pages/CoachFinder/CoachFinder";
import Faq from "pages/Faq/Faq";
import Success from "pages/Success/Success";
import Coaches from "pages/Home";
import CoacheDetails from "pages/Couch";

function App() {
  return (
    <Router>
      <Switch>
        <CustomRoute path={routes.MY_COACHING} exact component={MainCoaching} />
        <CustomRoute
          path={routes.INITIAL_CONSULTATION + "-old"}
          exact
          component={InitialConsultationOld}
        />
        <CustomRoute
          path={routes.COACHING_PRO + "-old"}
          exact
          component={CoachingProOld}
        />

        <CustomRoute
          path={routes.INITIAL_CONSULTATION}
          exact
          component={InitialConsultation}
        />
        <CustomRoute path={routes.COACHING_PRO} exact component={CoachingPro} />
        <CustomRoute
          path={routes.COACHING_PRO_APP}
          exact
          component={CoachingProApp}
        />
        <CustomRoute
          path={routes.COACHING_PRO_COACHING}
          exact
          component={CoachingProCoaching}
        />
        <CustomRoute
          path={routes.COACHING_FINDEN}
          exact
          component={CoachFinder}
        />
        <CustomRoute path={routes.FAQ} exact component={Faq} />
        <CustomRoute path={routes.SUCCESS} exact component={Success} />
        <CustomRoute path={routes.COACHES} exact component={Coaches} />
        <CustomRoute
          path={routes.COACHES_SLUG}
          exact
          component={CoacheDetails}
        />
        {/* <CustomRoute
            restricted
            path={routes.ROOT}
            exact
            component={<div>Root</div>}
          /> */}
        <Route
          exact
          path="/"
          render={() => <Redirect to={routes.MY_COACHING} />}
        />
        <Route path="*" render={() => "Route Not Found"} />
      </Switch>
    </Router>
  );
}

export default App;
