import styled from "styled-components"

export const WhyIamYourCoachWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;

  @media(max-width: 991.99px) {
    grid-template-columns: 1fr;
  } 
`

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3`
`

export const Description = styled.p`
  color: #44554c;
   
`

export const VisualContent = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    height: 500px;
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: 400px;
  }
`

export const Image = styled.div`
  position: relative;
  height: 100%;

  .shape, .img {
    position: absolute;
    top: 0;
    left: 0;
  }

  .shape {
    z-index: 12;
    width: 100%;
    height: 100%;
  }

  .img {
    width: 99%;
    height: 99%;
    margin: .5% auto 0;
    display: block;
    object-fit: cover;
    object-position: top;
  }
`
export const VisualContentWithoutShape = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
    /* filter: drop-shadow(0px 13px 20px #02332A26); */
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    height: 500px;
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`