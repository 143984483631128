import React from "react"
import Chip from "components/general/formComponents/chip/Chip"
import CoachingPlansPro from "components/CoachingPlansPro/CoachingPlansPro"
import * as F from "./coachFromFoodiaryPro.styled"
// Images
import Garantie from "assets/images/myCoaching/icon-garantie.svg"
import ActiveUser from "assets/images/myCoaching/Icon-active-user.svg"

const CoachFromFoodiaryPro = () => {
  return (
    <F.CoachingGlanceContainer>
      <F.CoachingGlanceLeft>
        <Chip text="PREISE" margin="0 0 30px 0" />
        <F.GlanceTitle>
          Warum ein Coach <br /> von Foodiary?
        </F.GlanceTitle>
        <F.GlanceText>
          Du profitierst von der Erfahrung eines professionellen
          Ernährungscoaches, erreichst dein gesetztes Ziel und veränderst dein
          Leben nachhaltig.
        </F.GlanceText>
        <F.GlanceText>
          Schluss mit dubiosen Diäten- und Ernährungsmythen. Wir kennen uns aus,
          wissen wie man eine Ernährungsumstellung richtig angeht und wir helfen
          dir diese dauerhaft durchzuführen, ohne sich im Alltag einzuschränken.
        </F.GlanceText>
        <F.GlanceTags>
          <img src={Garantie} alt="user" />
          <img src={ActiveUser} alt="user" />
        </F.GlanceTags>
      </F.CoachingGlanceLeft>
      {/* <F.desktopShow> */}
        <CoachingPlansPro />
      {/* </F.desktopShow> */}
      {/* <F.mobileShow>
        <PriceSlider />
      </F.mobileShow> */}
    </F.CoachingGlanceContainer>
  )
}

export default CoachFromFoodiaryPro
