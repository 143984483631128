import React from "react"
import { PopupButton } from "react-calendly"
import Chip from "components/general/formComponents/chip/Chip"
import * as C from "./nextSteps.styled"

// Images
import NextStepsPng from "assets/images/pngs/Foodiary-Zoom.png"
// import GreenTick from "assets/images/myCoaching/Icon-check.svg"

const NextSetps = () => {

  return (
    <C.Wrap>
      <C.TextContent>
        <Chip text="ERSTGESPRÄCH" margin="0 0 30px 0" />
        <C.Title>Nächsten freien Termin buchen</C.Title>
        <C.Description>
          Buche das nächst mögliche Erstgespräch mit einem unserer
          qualifizierten Ernährungscoaches.
        </C.Description>
        {/* <C.PlanBoxIcons className="procedure">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation>
              Wähle dein kostenloses, 45 minütiges Ersgespräch mit mir, indem du
              über den Buchungskalender einen freien Termin auswählst.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation>
              Nach erfolgreicher Terminvereinbarung erhältst du direkt eine
              E-Mail. Hier findest du den Zugang zu dem Online-Meeting über Zoom
              mit mir.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation>
              2 Tage vor unserem Termin erhältst du eine Bestätigungsmail. Wenn
              du diesen Termin bestätigst, dann findet er auch statt.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation>
              Erscheine bitte pünktlich im Call. Richte zuvor dein Ton- und die
              Video Einstellungen ein. Teste diese zuvor, um einen Reibungslosen
              Ablauf zu garantieren.
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons> */}
        <C.ButtonWrap>
          {/* <Button
            gradient="linear-gradient(102deg, #2ECF96 0%, #1C9D87 100%)"
            margin="0"
            absolute={{ width: "fit-content", height: "auto" }}
            buttonStyles={{
              fontSize: "16px",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontFamily: "RubikRegular",
              padding: '15px 30px'
            }}
          >
            Erstgespräch vereinbaren
          </Button> */}
          <PopupButton
            className="btn-primary"
            styles={{ margin: "0", fontSize: "20px" }}
            url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
            rootElement={document.getElementById("root")}
            text="Erstgespräch vereinbaren"
          />
        </C.ButtonWrap>
      </C.TextContent>

      <C.VisualContent>
        <img src={NextStepsPng} alt="zoom" />
      </C.VisualContent>
    </C.Wrap>
  )
}

export default NextSetps
