import styled from "styled-components";



export const InformationDescription = styled.div`
    font-size: 16px;
     
    color: #44554C;
    margin: 0 0 40px 0;

`;

export const desktopShow = styled.div`
    display: block;
    @media (max-width:600px){
        display: none;
    }

`

export const mobileShow = styled.div`
    display: none;
  @media (max-width:600px){
    display: block;
  }

`