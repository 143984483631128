import styled from "styled-components"

export const CoachingGlanceContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1.1fr 2fr;
  gap: 100px;
  @media (max-width: 1300px) {
    gap: 70px;
  }
  @media (max-width: 1199px) {
    grid-template-columns: 1fr;
    gap: 50px;
    word-break: break-word;
  }
`

export const CoachingGlanceLeft = styled.div.attrs({
  className: `${(props) => props.className}`,
})``

export const GlanceTitle = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  
  font-size: 40px;
  margin: 0 0 40px;
  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 45px;
    margin: 0 0 20px;
  }
`

export const GlanceText = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
   
  font-size: 18px;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 23px;
  }
`

export const GlanceTags = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  margin-top: 30px;
  img {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    img {
      width: 130px;
      height: 130px;
      &:first-child {
        width: 150px;
        height: 150px;
      }
    }
  }
`
export const desktopShow = styled.div`
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
`

export const mobileShow = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`
