import styled from "styled-components"

export const CoachingWithMeContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

export const ContentSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
   @media (max-width: 991.99px) {
    order: 1;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142C1F;
  font-size: 40px;
  
  line-height:60px;
  margin-bottom: 29px;
  @media (max-width:600px){
      font-size: 30px;
      line-height:45px;
  }
  
`

export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554C;
   
  font-size: 18px;
  line-height:28px;
  margin-bottom: 60px;
  @media (max-width:600px){
      font-size: 15px;
      line-height:23px;
      margin-bottom: 30px;
  }
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    display: block;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`

export const SocialBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  gap: 60px;

  img {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  @media(max-width: 767px) {
    gap: 20px;
  }
`