import styled from "styled-components";

export const CoachingGlanceContainer = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 2.1fr;
  gap: 50px;

  @media (max-width: 1199px) {
    grid-template-columns: 1fr;
    word-break: break-word;
  }
`;

export const CoachingGlanceLeft = styled.div``;

export const GlanceTitle = styled.h3`
  color: #142c1f;
  /* font-weight: 500;
  font-size: 2.2rem;
  margin: 0 0 40px; */
`;

export const GlanceText = styled.p`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
`;

export const GlanceTags = styled.div`
  display: flex;
  margin-top: 30px;
  img {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    img {
      width: 130px;
      height: 130px;
      &:first-child {
        width: 150px;
        height: 150px;
      }
    }
  }
`;
