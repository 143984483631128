import { X } from "react-feather";

const DisplaySelectedFilters = ({
  selectedObjectiveFilters,
  selectedSpecialityFilter,
  selectedNoOfTrainingsFilter,
  selectedExperienceFilter,
  selectedAlterFilter,
  handleRemoveFilter,
  children,
}) => {
  return (
    <>
      <div className="filters-head">
        <h4 className="active-filters-title">Filter</h4>
        {children}
        <div className="active-filters d-flex align-items-center flex-wrap">
          {selectedObjectiveFilters
            .filter((item) => item.checked)
            .map((item) => (
              <div className="active-filter d-flex align-items-center">
                <span>{item?.name}</span>
                <X
                  size={18}
                  onClick={() => handleRemoveFilter("Objective", item)}
                />
              </div>
            ))}

          {selectedSpecialityFilter
            .filter((item) => item.checked)
            .map((item) => (
              <div className="active-filter d-flex align-items-center">
                <span>{item?.name}</span>
                <X
                  size={18}
                  onClick={() => handleRemoveFilter("Speciality", item)}
                />
              </div>
            ))}

          {selectedNoOfTrainingsFilter
            .filter((item) => item.checked)
            .map((item) => (
              <div className="active-filter d-flex align-items-center">
                <span>{item?.name}</span>
                <X
                  size={18}
                  onClick={() => handleRemoveFilter("NoOfTrainings", item)}
                />
              </div>
            ))}

          {selectedExperienceFilter
            .filter((item) => item.checked)
            .map((item) => (
              <div className="active-filter d-flex align-items-center">
                <span>{item?.name}</span>
                <X
                  size={18}
                  onClick={() => handleRemoveFilter("Experience", item)}
                />
              </div>
            ))}

          {selectedAlterFilter
            .filter((item) => item.checked)
            .map((item) => (
              <div className="active-filter d-flex align-items-center">
                <span>{item?.name}</span>
                <X
                  size={18}
                  onClick={() => handleRemoveFilter("Alter", item)}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default DisplaySelectedFilters;
