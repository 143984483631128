import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

import FailureDialog from "components/FailureDialog";

import Navbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";

import CouchInfo from "components/CouchInfo/CouchInfo";

import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import * as C from "constants";

import ScheduleFirstConsultation from "components/ScheduleFirstConsultation/ScheduleFirstConsultation";
import SectionWrapper from "components/sectionWrapper/SectionWrapper";
import StartWithFoodiary from "components/StartWithFoodiary/StartWithFoodiary";
import ComprehensiveSupport from "components/ComprehensiveSupport/ComprehensiveSupport";
import FreeInitialSupport from "components/FreeInitialSupport/FreeInitialSupport";
import WhyFoodiaryCoach from "components/WhyFoodiaryCoach/WhyFoodiaryCoach";

const IMG_BASE_URL = C.IMG_BASE_URL;

function Couch() {
  const { search } = useLocation();
  let { slug } = useParams();

  const [couchProfile, setCouchProfile] = useState(null);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  // const [searchParams, setSearchParams] =)

  useEffect(() => {
    getCouchProfile({ id: slug.split("-")[2] });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const { success } = queryString.parse(search);
    if (success == "true") {
      setSuccessDialog(true);
    }
    if (success == "false") {
      setCancelDialog(true);
    }
    // searchParams.delete("success")
  }, [search]);

  const getCouchProfile = async (payload) => {
    try {
      const res = await axios.post(`${C.BASE_URL}coach/profile`, payload);
      setCouchProfile(res?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="couch-page">
        <section className="header-wrap">
          <Navbar />
          <div className="bg-gray"></div>
          <div className="container">
            <div className="header-details">
              {couchProfile && (
                <CouchInfo
                  coach={couchProfile}
                  baseUrl={IMG_BASE_URL}
                  onPaymentModal={() => setOpenPaymentModal(true)}
                />
              )}
            </div>
          </div>
        </section>
        <Navbar />
        <main>
          <div className="container">
            <SectionWrapper mobileMargin="0" margin="100px 0 0">
              {couchProfile && (
                <FreeInitialSupport
                  coach={couchProfile}
                  baseUrl={IMG_BASE_URL}
                />
              )}
            </SectionWrapper>
            <SectionWrapper mobileMargin="50px 0 0" margin="150px 0 0">
              <ComprehensiveSupport />
            </SectionWrapper>
            <SectionWrapper mobileMargin="50px 0 0" margin="100px 0 0">
              <ScheduleFirstConsultation
                className="desktopShow"
                coach={couchProfile}
              />
            </SectionWrapper>
            <SectionWrapper mobileMargin="50px 0 0" margin="100px 0 0">
              {/* <CoachFromFoodiaryPro  /> */}
              <WhyFoodiaryCoach />
            </SectionWrapper>
            <SectionWrapper mobileMargin="50px 0 0" margin="100px 0 0">
              <TestimonialSlider />
            </SectionWrapper>
          </div>
          <SectionWrapper mobileMargin="50px 0 0" margin="100px 0 0">
            <StartWithFoodiary coach={couchProfile} />
          </SectionWrapper>
          {/* <PaymentModal
            isOpen={openPaymentModal}
            onClose={() => setOpenPaymentModal(false)}
            couchProfile={couchProfile}
          /> */}
        </main>
      </div>
      <FailureDialog open={cancelDialog} close={() => setCancelDialog(false)} />
      <FooterNew />
    </>
  );
}

export default Couch;
