import React from "react";
import * as F from "./whyPersonalCoachWorthwhile.styled";

const WhyPersonalCoachWorthwhile = () => {
  return (
    <F.CoachingGlanceContainer>
      <div
        id="div_block-11-33"
        className="ct-div-block grid grid-cols-2 items-stretch lg-grid-cols-1"
      >
        <div
          id="div_block-12-33"
          className="ct-div-block justify-center lg-order-2"
        >
          <h2
            id="headline-14-33"
            className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12"
          >
            Warum sich ein persönlicher
            <br />
            Foodiary Coach lohnt?
          </h2>
          <div
            id="text_block-55-33"
            className="ct-text-block color-paragraph font-normal text-base mb-12"
          >
            Ein Foodiary Coach unterstützt dich tatkräftig dabei, deine
            Ernährungsumstellung zu meistern. Dabei kannst du dich voll und ganz
            auf die Umsetzung konzentrieren und die Arbeit ihm überlassen. Er
            betreut dich vollumfänglich, nimmt sich die Zeit, ist dein Anker im
            wirren Diätenwahnsinn und verhilft dir mit seiner Expertise zu
            deinem Erfolg.
          </div>
          <div id="div_block-40-33" className="ct-div-block w-full gap-5">
            <div id="div_block-41-33" className="ct-div-block oxel_iconlist__row">
              <div id="code_block-42-33" className="ct-code-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.3rem"
                  height="5.3rem"
                  viewBox="0 0 53 53"
                >
                  <g
                    id="Gruppe_8254"
                    data-name="Gruppe 8254"
                    transform="translate(-702.831 -3690.77)"
                  >
                    <circle
                      id="Ellipse_529"
                      data-name="Ellipse 529"
                      cx="26.5"
                      cy="26.5"
                      r="26.5"
                      transform="translate(702.831 3690.77)"
                      fill="#e5f5f2"
                    ></circle>
                    <path
                      id="Icon_ionic-ios-checkmark"
                      data-name="Icon ionic-ios-checkmark"
                      d="M30.577,14.021l-1.655-1.7a.356.356,0,0,0-.263-.113h0a.341.341,0,0,0-.263.113L16.923,23.876,12.748,19.7a.364.364,0,0,0-.527,0l-1.674,1.674a.375.375,0,0,0,0,.536l5.266,5.266a1.665,1.665,0,0,0,1.1.536A1.745,1.745,0,0,0,18,27.2h.009L30.587,14.557A.4.4,0,0,0,30.577,14.021Z"
                      transform="translate(708.751 3697.31)"
                      fill="#1c9d87"
                    ></path>
                  </g>
                </svg>
              </div>
              <div
                id="text_block-43-33"
                className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal"
              >
                Erreiche deine Ziele einfacher und effizienter
              </div>
            </div>
            <div id="div_block-44-33" className="ct-div-block oxel_iconlist__row">
              <div id="code_block-45-33" className="ct-code-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.3rem"
                  height="5.3rem"
                  viewBox="0 0 53 53"
                >
                  <g
                    id="Gruppe_8254"
                    data-name="Gruppe 8254"
                    transform="translate(-702.831 -3690.77)"
                  >
                    <circle
                      id="Ellipse_529"
                      data-name="Ellipse 529"
                      cx="26.5"
                      cy="26.5"
                      r="26.5"
                      transform="translate(702.831 3690.77)"
                      fill="#e5f5f2"
                    ></circle>
                    <path
                      id="Icon_ionic-ios-checkmark"
                      data-name="Icon ionic-ios-checkmark"
                      d="M30.577,14.021l-1.655-1.7a.356.356,0,0,0-.263-.113h0a.341.341,0,0,0-.263.113L16.923,23.876,12.748,19.7a.364.364,0,0,0-.527,0l-1.674,1.674a.375.375,0,0,0,0,.536l5.266,5.266a1.665,1.665,0,0,0,1.1.536A1.745,1.745,0,0,0,18,27.2h.009L30.587,14.557A.4.4,0,0,0,30.577,14.021Z"
                      transform="translate(708.751 3697.31)"
                      fill="#1c9d87"
                    ></path>
                  </g>
                </svg>
              </div>
              <div
                id="text_block-46-33"
                className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal"
              >
                Profitiere von seiner Erfahrung und dem Fachwissen
              </div>
            </div>
            <div id="div_block-47-33" className="ct-div-block oxel_iconlist__row">
              <div id="code_block-48-33" className="ct-code-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.3rem"
                  height="5.3rem"
                  viewBox="0 0 53 53"
                >
                  <g
                    id="Gruppe_8254"
                    data-name="Gruppe 8254"
                    transform="translate(-702.831 -3690.77)"
                  >
                    <circle
                      id="Ellipse_529"
                      data-name="Ellipse 529"
                      cx="26.5"
                      cy="26.5"
                      r="26.5"
                      transform="translate(702.831 3690.77)"
                      fill="#e5f5f2"
                    ></circle>
                    <path
                      id="Icon_ionic-ios-checkmark"
                      data-name="Icon ionic-ios-checkmark"
                      d="M30.577,14.021l-1.655-1.7a.356.356,0,0,0-.263-.113h0a.341.341,0,0,0-.263.113L16.923,23.876,12.748,19.7a.364.364,0,0,0-.527,0l-1.674,1.674a.375.375,0,0,0,0,.536l5.266,5.266a1.665,1.665,0,0,0,1.1.536A1.745,1.745,0,0,0,18,27.2h.009L30.587,14.557A.4.4,0,0,0,30.577,14.021Z"
                      transform="translate(708.751 3697.31)"
                      fill="#1c9d87"
                    ></path>
                  </g>
                </svg>
              </div>
              <div
                id="text_block-49-33"
                className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal"
              >
                Verlasse dich auf die Zielüberprüfung und dauerhafte Anpassung
              </div>
            </div>
          </div>
          {/* <a
            id="link_text-353-33"
            className="ct-link-text foodiary-green-button mt-8"
            href="https://mycoach.foodiary.app/"
            target="_blank"
            rel="noreferrer"
          >
            Coach finden
          </a> */}
        </div>
        <div id="div_block-37-33" className="ct-div-block lg-order-1">
          <img
            id="image-38-33"
            alt="Foodiary-Coach-Support-v3"
            src="https://foodiary.app/data/Foodiary-Coach-Support-v3.jpg"
            className="ct-image "
            srcset="https://foodiary.app/data/Foodiary-Coach-Support-v3.jpg 1080w, https://foodiary.app/data/Foodiary-Coach-Support-v3-300x300.jpg 300w, https://foodiary.app/data/Foodiary-Coach-Support-v3-1024x1024.jpg 1024w, https://foodiary.app/data/Foodiary-Coach-Support-v3-150x150.jpg 150w, https://foodiary.app/data/Foodiary-Coach-Support-v3-768x768.jpg 768w"
            sizes="(max-width: 1080px) 100vw, 1080px"
          />
        </div>
      </div>
    </F.CoachingGlanceContainer>
  );
};

export default WhyPersonalCoachWorthwhile;
