import { useSelector } from "react-redux"
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather"
import Slider from "react-slick"
import * as C from "./cardSlider.style"
import StepCard from "components/general/stepCard/StepCard"
import Button from "components/general/formComponents/button/Button"
// Images
import IconFood from "assets/images/myCoaching/Icon-food.svg"

const TestimonialSlider = ({ list, baseUrl }) => {
  const coachFeedback = useSelector((state) => state.coach.feedback)

  const settings = {
    className: "",
    slidesToShow: "1",
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }

  return (
    <>
      <C.SectionTitleMobile>
        STARTE JETZT GEMEINSAM MIT MIR <br /> <b>DEIN 12- WOCHEN COACHING!</b>
      </C.SectionTitleMobile>
      <C.SubInfo>
        In drei Schritten erfährst du, wie du dein kostenloses Erstgespräch mit
        deinem Ernährungsberater vereinbarst und du zeitnah mit ihm deine
        Ernährungsumstellung starten kannst.
      </C.SubInfo>
      <C.SliderCard className="cardSlider">
        <Slider className="slick-slider" {...settings}>
          <StepCard
            title="Coaching buchen"
            description="Entscheide dich dafür, gemeinsam mit uns dein Ziel zu erreichen. Buche deinen Wunschtermin."
            icon={IconFood}
          />
          <StepCard
            title="Ernährungsplan erstellen"
            description="In einem Video-Call erstellt dein Ernährungscoach deinen persönlichen Ernährungsplan."
            icon={IconFood}
          />
          <StepCard
            title="Starte mit deinem Coach"
            description="Wir beantworten dir deine Fragen und starten mit deinem 3-Monatigem Coaching."
            icon={IconFood}
          />
        </Slider>
        <Button
          className="custum-btn"
          gradient="linear-gradient(102deg, #2ECF96 0%, #1C9D87 100%)"
          margin="0"
          absolute={{ height: "auto", margin: "50px auto 0" }}
          buttonStyles={{
            fontSize: "20px",
            backgroundColor: "transparent",
            color: "#ffffff",
          }}
        >
          Termin vereinbaren
        </Button>
      </C.SliderCard>
    </>
  )
}

export default TestimonialSlider
