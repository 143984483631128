import React, { useState } from "react";
import Chip from "components/general/formComponents/chip/Chip";
import Accordian from "components/Accordians/Accordian";
import * as C from "./coachingAccordian.styled";
// Images
import FoodiaryRezepte from "assets/images/pngs/Foodiary-Rezepte.png";
import FoodiaryRezeptkarte from "assets/images/pngs/Foodiary-Rezeptkarte.png";
import FoodiaryCoachCard from "assets/images/pngs/Foodiary-Coach-card.png";
import FoodiaryCoaching from "assets/images/pngs/Coaching-Academy.jpg";
import FoodiaryDashboard from "assets/images/pngs/ModerneApp.png";
import FoodiaryKalorienverlauf from "assets/images/pngs/weekly-foodiary.jpg";

const Questions = [
  {
    id: 1,
    img: FoodiaryRezepte,
    title: "Persönlicher Ernährungsberater",
    info: "Dein Foodiary Coach steht dir als dein persönlicher Ernährungsberater an deiner Seite und betreut dich, innerhalb von 3 Monaten dein Ziel zu erreichen.",
  },
  {
    id: 2,
    img:  FoodiaryDashboard,
    title: "Moderne Ernährungsplaner-App",
    info: "Die tägliche Ernährungsplanung wird dir durch unsere Ernährungsplaner-App deutlich vereinfacht. Du wählst flexibel deine Rezepte und erreichst dein Tagesziel.",
  },
  {
    id: 3,
    img: FoodiaryRezeptkarte,
    title: "Über 1700 Rezepte",
    info: `Über 1700 Rezepte werden dank künstlicher Intelligenz optimal auf deinen Ernährungsplan angepasst. Du entscheidest ganz nach deinen Wünschen, welches Rezept du zubereiten möchtest.`,
  },

  {
    id: 4,
    img:  FoodiaryKalorienverlauf,
    title: "Fortschrittkontrolle",
    info: "Dein Foodiary Coach überwacht deine Fortschritte und Erfolge. Sollte etwas nicht wie gewünscht Ablaufen, wird er aktiv eingreifen und dich unterstützen.",
  },
  {
    id: 5,
    img:  FoodiaryCoaching,
    title: "Foodiary Academy",
    info: "Damit deine Ernährungsumstellung ein dauerhafter Erfolg bleibt, erhältst du von uns in der Foodiary Academy über 15 Lernvideos und E-Books.",
  },
  {
    id: 6,
    img: FoodiaryCoachCard,
    title: "Wöchentliche Beratungsgespräche",
    info: "In wöchentlichen Gruppengesprächen beantwortet dir ein Ernährungsberater deine offenen Fragen und vermittelt dir Wissen aus der Praxis.",
  },
];

const CoachingAccordian = () => {
  const [image, setImage] = useState(FoodiaryRezepte);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const onToggle = (question) => {
    setImage(question?.img);
    setActiveQuestion(question);
  };

  return (
    <C.Wrap>
      <C.TextContent>
        <Chip text="DAUERHAFTE BETREUUNG" margin="0 0 30px 0" />
        <C.Title className="ct-text-block color-dark text-left text-4xl">
          Vollumfängliche Betreuung durch deinen Foodiary Coach
        </C.Title>
        <Accordian
          data={Questions}
          activeQuestion={activeQuestion}
          onExpanded={(question) => onToggle(question)}
        />
      </C.TextContent>
      <C.VisualContent>
        <img src={image} alt="accord" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default CoachingAccordian;
