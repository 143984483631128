import React from 'react';
import { PopupButton } from "react-calendly"
import * as F from './dietMadeEasyNew.style';

// Images
import ErnaehrungsumstellungLeicht from 'assets/images/partnership/FoodiaryCoachingPRODashboard.png';

const DietMadeEasyNew = () => {
  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <F.Title>
          Ernährungsumstellung leicht gemacht
        </F.Title>
        <F.Description>
          Das persönliche Ernährungscoaching PRO unterstützt dich dabei innerhalb der nächsten 3 Monaten dein persönliches Ziel zu erreichen. Ein dir zugewiesener und fester Ernährungscoach entwickelt gemeinsam mit dir deinen persönlichen Ernährungsplan. Er unterstütz dich beim Erreichen deines Zieles und steht dauerhaft an deiner Seite.
        </F.Description>
        <PopupButton
          className="btn-primary custum-btn"
          url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
          rootElement={document.getElementById("root")}
          text="Termin vereinbaren"
        />
      </F.ContentSection>
      <F.VisualContent>
        <img src={ErnaehrungsumstellungLeicht} alt="coach" />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  )
}

export default DietMadeEasyNew