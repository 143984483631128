import React from "react"
import Chip from "components/general/formComponents/chip/Chip"
import { PopupButton } from "react-calendly"
import * as F from "./dietChange.style"

// Images
import Coach from "assets/images/partnership/Foodiary-Coaching-start.svg"

const DietChange = () => {
  return (
    <F.WhyIamYourCoachWrap>
      {/* <F.VisualContent>
        <F.Image>
          <img className="shape" src={ShapeOutline} alt="Shape" />
          <img className="img" src={Coach} alt="Foodiary-Coaching-Bild2" />
        </F.Image>
      </F.VisualContent> */}
      <F.VisualContentWithoutShape>
        <img className="img" src={Coach} alt="Foodiary-Coaching-Bild2" />
      </F.VisualContentWithoutShape>
      <F.ContentSection>
        <Chip text="ERSTGESPRÄCH" margin="0 0 3rem 0"/>
        <F.Title className="ct-headline foodiary-h2 color-dark text-4xl">Starte deine Ernährungsumstellung!</F.Title>
        <F.Description className="ct-text-block color-paragraph text-base mt-10 mb-14">
          Unserer Ernährungscoaches unterstützen dich bei deiner Ernährungsumstellung. Starte jetzt gemeinsam mit Foodiary und erreiche dein gesetztes Ziel. Ob Abnehmen, Muskeln aufbauen oder gesunde Ernährung, wir stehen an deiner Seite.
        </F.Description>
        <PopupButton
          className="btn-primary  foodiary-green-button cursor-pointer"
          rootElement={document.getElementById("root")}
          url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
          text="Termin vereinbaren"
        />
      </F.ContentSection>
    </F.WhyIamYourCoachWrap>
  )
}

export default DietChange
