import React from "react"
import { PopupButton } from "react-calendly"
import StepCard from "components/general/stepCard/StepCard"
// Images
import IconFood from "assets/images/myCoaching/Icon-food.svg"
import * as F from "./scheduleFirstConsultation.style"

const ScheduleFirstConsultation = () => {
  return (
    <F.Wrap>
      <F.SectionTitle className="ct-text-block font-light color-dark text-4xl mt-0 mb-8 text-center">
        <b>JETZT DEIN ERSTGESPRÄCH VEREINBAREN!</b>
      </F.SectionTitle>
      <F.SectionTitleMobile className="ct-text-block font-light color-dark text-4xl mt-0 mb-8 text-center">
        <b>JETZT DEIN ERSTGESPRÄCH VEREINBAREN!</b>
      </F.SectionTitleMobile>
      <F.SubInfo className="ct-text-block color-paragraph font-normal text-base mb-12 mx-auto text-center">
        In drei Schritten erfährst du, wie du dein kostenloses Erstgespräch mit deinem Ernährungsberater vereinbarst und du zeitnah mit ihm deine Ernährungsumstellung starten kannst.
      </F.SubInfo>
      <F.CardsWrap>
        <StepCard
          title="Deine Anfrage"
          description="Klicke auf den 
          Button „Termin 
          vereinbaren“. Im 
          Anschluss öffnet sich 
          der Terminkalender. 
          Hier wählst du 
          flexibel deinen 
          Wunschtermin."
          icon={IconFood}
        />
        <StepCard
          title="Das Gespräch"
          description="Während unserem 
          Erstgespräch gehen 
          wir auf deine 
          Wünsche und 
          Bedürfnisse ein und 
          erstellen deinen 
          Ernährungsplan."
          icon={IconFood}
        />
        <StepCard
          title="Gemeinsam ans Ziel"
          description="Du entscheidest 
          unverbindlich ob wir 
          dich während deiner 
          Ernährungsumstellun
          g betreuen dürfen."
          icon={IconFood}
        />
      </F.CardsWrap>
      <PopupButton
        className="ct-link-text foodiary-green-button text-decoration-none mt-12 mx-auto"
        url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
        rootElement={document.getElementById("root")}
        text="Termin vereinbaren"
      />
    </F.Wrap>
  )
}

export default ScheduleFirstConsultation
