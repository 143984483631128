import React from "react";
import * as F from "./foodiaryPROAccessFullFeatures.style";

const FoodiaryPROAccessFullFeatures = () => {
  return (
    <F.Wrap>
      <div
        id="div_block-187-33"
        className="ct-div-block w-full grid grid-cols-2 items-stretch md-grid-cols-1 lg-grid-cols-1"
      >
        <div id="div_block-188-33" className="ct-div-block centered">
          <img
            id="image-201-33"
            alt="Foodiay-Mockup-hand-coaching-features-v2"
            src="https://foodiary.app/data/Foodiay-Mockup-hand-coaching-features-v2.jpg"
            className="ct-image "
            srcset="https://foodiary.app/data/Foodiay-Mockup-hand-coaching-features-v2.jpg 1620w, https://foodiary.app/data/Foodiay-Mockup-hand-coaching-features-v2-300x300.jpg 300w, https://foodiary.app/data/Foodiay-Mockup-hand-coaching-features-v2-1024x1024.jpg 1024w, https://foodiary.app/data/Foodiay-Mockup-hand-coaching-features-v2-150x150.jpg 150w, https://foodiary.app/data/Foodiay-Mockup-hand-coaching-features-v2-768x768.jpg 768w, https://foodiary.app/data/Foodiay-Mockup-hand-coaching-features-v2-1536x1536.jpg 1536w"
            sizes="(max-width: 1620px) 100vw, 1620px"
          />
        </div>
        <div id="div_block-190-33" className="ct-div-block justify-center">
          <div
            id="headline-191-33"
            className="ct-headline py-3 px-6 uppercase text-base rounded-full font-semibold color-secondary bg-alt"
          >
            Foodiary App Pro
          </div>
          <h2
            id="headline-192-33"
            className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12"
          >
            Inklusive Foodiary PRO mit vollem Zugriff auf alle Funktionen 😋
          </h2>
          <div
            id="text_block-197-33"
            className="ct-text-block color-paragraph font-normal text-base"
          >
            Das umfangreiche Gesamtpaket wird durch deinen täglichen, digitalen
            Ernährungsplaner abgerundet. Während deiner Ernährungsumstellung
            erhältst du natürlich alle PRO Funktionen der Foodiary App. So
            erleichtern dir über 1700 Rezepte und Einkaufslisten den Alltag.
            <br />
            <br />
            <span id="span-198-33" className="ct-span color-paragraph-alt">
              Damit dein Coaching auch ein voller Erfolg wird, haben wir sogar
              spezielle Coaching PRO Features entwickelt.
            </span>
          </div>
          <div id="div_block-202-33" className="ct-div-block mt-14">
            <a
              id="link-203-33"
              className="ct-link"
              href="https://apps.apple.com/de/app/foodiary-food-meal-planner/id1502395764"
              target="_blank"
              rel="noreferrer"
            >
              <img
                id="image-204-33"
                alt="App Store Download"
                src="https://foodiary.app/data/AppStore-Download.png"
                className="ct-image"
                srcset="https://foodiary.app/data/AppStore-Download.png 480w, https://foodiary.app/data/AppStore-Download-300x100.png 300w"
                sizes="(max-width: 480px) 100vw, 480px"
              />
            </a>
            <a
              id="link-205-33"
              className="ct-link"
              href="https://play.google.com/store/apps/details?id=com.foodiary.nutrition"
              target="_blank"
              rel="noreferrer"
            >
              <img
                id="image-206-33"
                alt="Download Play Store"
                src="https://foodiary.app/data/Download-PlayStore.png"
                className="ct-image"
                srcset="https://foodiary.app/data/Download-PlayStore.png 564w, https://foodiary.app/data/Download-PlayStore-300x91.png 300w"
                sizes="(max-width: 564px) 100vw, 564px"
              />
            </a>
          </div>
        </div>
      </div>
    </F.Wrap>
  );
};

export default FoodiaryPROAccessFullFeatures;
