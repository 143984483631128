import styled from "styled-components"

export const CoachingGlanceContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`

export const CoachingGlanceLeft = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  padding-top: 30px;
`

export const GlanceTitle = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  
  font-size: 40px;
  margin: 0 0 40px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`

export const GlanceText = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
   
  font-size: 18px;
  margin-bottom: 30px;
`

export const GlanceTags = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  margin-top: 30px;
  img {
    max-width: 100%;
  }

  @media (max-width: 600px){
    img {
      width: 150px;
    }
  }
`

export const PlansBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})``

export const OnePlan = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  max-width: 450px;
  margin-inline: auto;
`
