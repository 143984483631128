import React from "react";
import { PopupButton } from "react-calendly";
import StepCard from "components/general/stepCard/StepCard";
// Images
import ThumsUp from "assets/images/functions/Coach/thumbs-up.svg";
import Peace from "assets/images/functions/Coach/peace.svg";
import Rock from "assets/images/functions/Coach/rock.svg";
import * as F from "./stepsToYourCoach.style";

const StepsToYourCoach = () => {
  return (
    <F.Wrap>
      <F.SectionTitle className="ct-headline foodiary-h2 color-dark text-4xl text-center">
        <b>In nur 3 Schritten zu deinem Coach</b>
      </F.SectionTitle>
      <F.CardsWrap>
        <StepCard
          title="Coach finden"
          description="Wähle deinen persönlichen Ernährungsberater aus unseren Foodiary Coaches aus und vereinbare einen Termin für dein kostenfreies Erstgespräch."
          icon={ThumsUp}
        />
        <StepCard
          title="Kostenloses Erstgespräch"
          description="In deinem 45-minütigen Erstgespräch erstellt dein Coach gemeinsam mit dir deinen Ernährungsplan. Kläre offene Fragen und buche ganz einfach das Coaching PRO."
          icon={Peace}
        />
        <StepCard
          title="Let's go!"
          description="Starte direkt mit deinem persönlichen Ernährungsberater dein 3-monatiges Coaching und rockt zusammen deine Ernährungsumstellung.."
          icon={Rock}
        />
      </F.CardsWrap>
      {/* <PopupButton
        className="btn-primary w-auto ct-link-text foodiary-green-button cursor-pointer mt-12 d-block mx-auto"
        url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
        rootElement={document.getElementById("root")}
        text="Coach finden"
      /> */}

      <a
        className="btn-primary w-auto ct-link-text foodiary-green-button cursor-pointer mt-12 d-block mx-auto"
        href="https://begin.foodiary.app/coaches"
      >
        Coach finden
      </a>
    </F.Wrap>
  );
};

export default StepsToYourCoach;
