import styled from "styled-components";

export const ChipWrap = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  background: ${(props) => props.background || "#E7FAF3"};
  padding: 12px 24px;
  border-radius: 42px;

  @media screen and (max-width: 600px) {
    margin: ${(props) => props.marginMobile};
  }
`;

export const Text = styled.p`
  color: ${(props) => props.color || "#2ECF96"};
  /* font-weight: 400; */
  cursor: pointer;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.32px;
  /* font-size: 1rem; */
`;
