import React from 'react';
import { PopupButton } from "react-calendly"
import { APPEND_CALENDLY_URL } from "constants"
import * as F from "./startWithFoodiary.styles";


const StartWithFoodiary = ({ coach = null }) => {
    return (
        <F.Wrapper className='d-flex align-items-center justify-content-center'>
            <F.Content className='d-flex flex-column align-items-center text-center'>
                <F.Title id="text_block-52-15" className='ct-text-block color-dark text-3xl font-bold my-0'>
                    Jetzt mit Foodiary Coach starten!
                </F.Title>
                <F.Description className='ct-text-block text-base color-paragraph font-normal mt-12 mb-12 text-center'>
                    Dein persönlicher Ernährungsberater sorgt für Klarheit im wirren Diätenwahnsinn. 
                    <br /> Er steht auf deinem Weg zu einem gesünderem Leben an deiner Seite.
                </F.Description>
                <PopupButton
                    className="ct-link-text foodiary-green-button hover-float"
                    styles={{ width: 'auto', display: 'block' }}
                    url={`${coach?.pp_first_meeting_calendly_url_profilpage}${APPEND_CALENDLY_URL}`}
                    // url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
                    rootElement={document.getElementById("root")}
                    text="Erstgespräch vereinbaren"
                />
            </F.Content>
        </F.Wrapper>
    )
}

export default StartWithFoodiary;
