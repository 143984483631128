import React, { useEffect } from 'react';
import { useDispatch } from "react-redux"
// Components
import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper";
import DietMadeEasyNew from 'components/DietMadeEasyNew/DietMadeEasyNew';
import StandardProgramNew from 'components/StandardProgramNew/StandardProgramNew';
import NutritionCoachNew from 'components/NutritionCoachNew/NutritionCoachNew';
import YoyoEffectNew from 'components/YoyoEffectNew/YoyoEffectNew';
import CoachingProNow from 'components/CoachingProNow/CoachingProNow';
import BenefitsGlanceNew from 'components/BenefitsGlanceNew/BenefitsGlanceNew';
import ToolsYourSuccess from 'components/ToolsYourSuccess/ToolsYourSuccess';
import SmallSelection from 'components/SmallSelection/SmallSelection';
import UnbeatableTeam from 'components/UnbeatableTeam/UnbeatableTeam';
import CoachFromFoodiaryPro from 'components/CoachFromFoodiaryPro/CoachFromFoodiaryPro';
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import StepCarMobile from "components/CardSliderTwo/CardSliderTwo";
import PromotedCoachList from "components/PromotedCoachList/PromotedCoachList";

// Styles
import * as G from 'styles/General.Styles';
import * as C from './coachingPro.styles'
// Redux
import * as CoachActions from "redux/coach/coach.actions"

const CoachingPro = () => {

    const dispatch = useDispatch()
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        dispatch(
            CoachActions.coachFeedbackRequest({
                callback: () => null,
            })
        )
    }, [dispatch])

    return (
        <>
            <G.Container>
                <Navbar />
                <SectionWrapper mobileMargin="50px 0 0" margin="100px 0 0">
                    <DietMadeEasyNew />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0">
                    <StandardProgramNew />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0">
                    <NutritionCoachNew />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 100px">
                    <PromotedCoachList />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0">
                    <YoyoEffectNew />
                </SectionWrapper>
                <SectionWrapper mobileMargin="50px 0 0" margin="100px 0 0">
                    <C.desktopShow>
                        <CoachingProNow />
                    </C.desktopShow>
                    <C.mobileShow>
                        <StepCarMobile />
                    </C.mobileShow>
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0">
                    <BenefitsGlanceNew />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0">
                    <ToolsYourSuccess />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0">
                    <SmallSelection />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0">
                    <UnbeatableTeam />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 0">
                    <CoachFromFoodiaryPro />
                </SectionWrapper>
                <SectionWrapper margin="100px 0 80px">
                    <TestimonialSlider />
                </SectionWrapper>
            </G.Container>
            <Footer />
        </>
    )
}

export default CoachingPro;
