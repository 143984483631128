import React, { useState } from "react"
import { Link } from "react-router-dom";
import Accordian from "components/Accordians/Accordian"
import * as C from "./generalQuestion.styled"
// Images
import FoodiaryRezepte from "assets/images/pngs/Foodiary-Rezepte.png"

const Questions = [
  {
    id: 1,
    title: "Wird meine Ernährungsplanung begleitet?",
    info: `Ja. Unsere Ernährungscoaches werden dich während den 3 Monaten der 
    Ernährungsumstellung persönlich betreuen und stehen dir jederzeit als Ansprechpartner zur 
    Verfügung. Zudem erhältst du Zugang zu unserem Coaching Dashboard mit Lernvideos, E-Books und dem Wochenupdate.`,
  },
  {
    id: 2,
    title: "Ist es ein persönlicher Plan?",
    info: `Selbstverständlich! Jeder Ernährungsplan von Foodiary ist einzigartig und speziell auf dich 
    und dein Ziel entwickelt worden. Für die Zeit der Ernährungsumstellung errechnen wir dir 
    deinen Kalorienbedarf und ermitteln die optimal Ernährungsform. Dies wird direkt und 
    persönlich für dich in die Ernährungsplaner-App integriert.`,
  },
  {
    id: 3,
    title: "Benötige ich zusätzlich die App?",
    info: `Unserer App begleitet dich durch den Alltag. Sie bietet dir eine Vielzahl von 
    abwechslungsreichen Rezepten, zeigt deinen Kalorienbedarf an und erleichtert die tägliche 
    Planung. Unsere App bietet ein zentrales Element deiner Ernährungsumstellung. 
    Selbstverständlich werden dir während dem Coaching alle PRO-Funktionen kostenlos 
    freigeschaltet.`,
  },
  {
    id: 4,
    title: "Wie funktioniert das Coaching?",
    info: `Nachdem du dich für unser Coaching entschieden hast und dieses gebucht hast, wirst du im 
    ersten Schritt gebeten über unser Buchungstool einen Termin mit deinem Coach zu 
    vereinbaren. In diesem Kennenlernen erstellt dein Ernährungscoach zunächst deinen 
    Ernährungsplan, beantwortet deine Fragen und richtet die kommenden Wochen ein. Du 
    erhältst zudem direkt Zugriff auf den Coaching-Bereich. Hier findest du nicht nur die Academy, 
    sondern auch den persönlichen Kontakt zu deinem Ernährungscoach.`,
  },
  {
    id: 5,
    title: "Kann ich die App ohne Coaching verwenden?",
    info: `Ja. Es ist möglich unsere App auch ohne Coaching zu verwenden. Hierzu findest du mehr 
    Informationen unter <a href="https://www.foodiary.app" target="_blank">https://www.foodiary.app</a>`,
  },
  {
    id: 6,
    title: "Wo kann ich das Coaching buchen?",
    info: `Das Coaching kannst du direkt <a href="/coach-finden">hier</a> buchen.`,
  },
  {
    id: 7,
    title: "Wann bekomme ich meinen Termin?",
    info: `In der Regel können wir dir direkt innerhalb 3 Tagen nach deiner Buchung einen Termin 
    anbieten. Deinen Termin wählst du selbstständig aus.`,
  },
]

const GeneralQuestion = () => {
  const [image, setImage] = useState(FoodiaryRezepte)
  const [activeQuestion, setActiveQuestion] = useState(null)

  const onToggle = (question) => {
    setActiveQuestion(question)
  }

  return (
    <C.Wrap>
      <C.TextContent>
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl mb-12">Allgemeine Fragen:</C.Title>
        <Accordian
          data={Questions}
          activeQuestion={activeQuestion}
          onExpanded={(question) => onToggle(question)}
        />
      </C.TextContent>
    </C.Wrap>
  )
}

export default GeneralQuestion
