import styled from "styled-components"

export const CoachingAccordianContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  align-items: center;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
    height: auto;
    max-width: 550px;
    max-height: 650px;
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: flex-start;
    img {
      margin-top: 100px;
    }
  }

  @media (max-width: 991.99px) {
    height: auto;
    order: 1;
    img {
      max-width: 500px;
      margin-inline: auto;
      display: block;
    }
  }

  @media (max-width: 767.99px) {
    display: none;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 40px;
  
  margin-bottom: 50px;
  @media (max-width:600px){
    font-size:30px;
    line-height:45px;
    margin-bottom:20px;
  }
`

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
    order: 2;
  }
`
