import React, { useState } from "react"
import Chip from "components/general/formComponents/chip/Chip";
import Accordian from 'components/Accordians/Accordian';
import * as C from './benefitsGlanceNew.styled';
// Images
import Academy from "assets/images/partnership/2/academy.png"
import Ernahrungsplaner from "assets/images/partnership/2/ernahrungsplaner-app.png"
import Ernahrungsplan from "assets/images/partnership/2/foodiary-ernahrungsplan.png"
import Personliche from "assets/images/partnership/2/personliche-beratung.png"
import Rezepte from "assets/images/partnership/2/Rezepte.png"
import Wochenupdate from "assets/images/partnership/2/Wochenupdate.png"

const Questions = [
  {
    id: 1,
    img: Personliche,
    title: "Persönlicher Ernährungsberater",
    info: `Ein persönlicher 
    Ernährungsberater 
    unterstütz dich während 
    den nächsten 3 Monaten 
    dein Ziel zu erreichen.`,
  },
  {
    id: 2,
    img: Ernahrungsplan,
    title: "Eigener Ernährungsplan",
    info: "Über 1700 Rezepte werden dank künstlicher Intelligenz optimal auf dein Ernährungsplan angepasst. Du Entscheidest ganz nach deinen Wünschen, welches Rezept du zubereiten möchtest.",
  },
  {
    id: 3,
    img: Ernahrungsplaner,
    title: "Ernährungsplaner App",
    info: `Mit der PRO Version 
    unserer App stehen dir 
    alle Funktionen für eine 
    einfache Planung deiner 
    täglichen Ernährung zur 
    Verfügung`,
  },
  {
    id: 4,
    img: Wochenupdate,
    title: "Wochenupdates",
    info: `Damit deine 
    Ernährungsumstellung 
    ein voller Erfolg wird, 
    überwacht ein 
    persönlicher Coach 
    deinen wöchentlichen 
    Fortschritt und schreitet 
    ein, sofern es nötig ist.
    `,
  },
  {
    id: 5,
    img: Rezepte,
    title: "Über 1700 Rezepte",
    info: `Über 1700 
    abwechslungsreiche 
    Rezepte erwarten dich. 
    Von vegetarisch und 
    vegan bis hin zu Meal 
    Prep ist alles dabei. Bei 
    uns wird Flexibilität 
    großgeschrieben.`,
  },
  {
    id: 6,
    img: Academy,
    title: "Foodiary Academy",
    info: `Damit deine 
    Ernährungsumstellung 
    ein dauerhafter Erfolg 
    bleibt, erhältst du von 
    uns in der Foodiary 
    Academy über 15 
    Lernvideos und E-Books.`,
  },
]


const BenefitsGlance = () => {
  const [image, setImage] = useState(Personliche)
  const [activeQuestion, setActiveQuestion] = useState(null)

  const onToggle = (question) => {
    setImage(question?.img)
    setActiveQuestion(question)
  }

  return (
    <C.CoachingAccordianContainer>
      <C.TextContent>
        <Chip text="FEATURES" margin="0 0 30px 0" />
        <C.Title>Deine Vorteile im Überblick</C.Title>
        <Accordian data={Questions} activeQuestion={activeQuestion} onExpanded={(question) => onToggle(question)} />
      </C.TextContent>
      <C.VisualContent>
        <img src={image} alt="advantage" />
      </C.VisualContent>
    </C.CoachingAccordianContainer>
  )
}

export default BenefitsGlance