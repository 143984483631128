import React from "react"
import { Link, NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import * as F from "./footer.style"
import * as G from "styles/General.Styles"

// Images
import Logo from "assets/images/myCoaching/Foodiary-Logo-White.png"
import PlayStore from "assets/images/myCoaching/play-store.png"
import AppStore from "assets/images/myCoaching/app-store.png"

const Footer = () => {
  const coach = useSelector((state) => state.coach.coach)

  return (
    <F.FooterWrap>
      <G.Container>
        <F.FooterContent>
          <F.FooterContentLogoSection>
            <Link to="/">
              <F.Logo src={Logo} alt="Footer Logo" />
            </Link>
            <F.AppLogo>
              <a href="https://www.foodiary.app/get-the-app/">
                <img src={PlayStore} alt="Android App" />
              </a>
              <a href="https://www.foodiary.app/get-the-app/">
                <img src={AppStore} alt="Ios App" />
              </a>
            </F.AppLogo>
            <F.FooterPrivacyLinks>
              <li>
                <a href="https://www.foodiary.app/impressum">Impressum</a>
              </li>
              <li>
                <a href="https://www.foodiary.app/datenschutz">Datenschutz</a>
              </li>
              <li>
                <a href="https://www.foodiary.app/agb">AGB</a>
              </li>
            </F.FooterPrivacyLinks>
          </F.FooterContentLogoSection>
          <F.FooterContentUlSection>
            <F.FooterContentUlSectionInner>
              <F.Title>Coaching</F.Title>
              <F.FooterLinks>
                <li>
                  <NavLink to="/erstgespraech">Erstgespräch</NavLink>
                </li>
                <li>
                  <NavLink to="/coaching-pro">Coaching PRO</NavLink>
                </li>
                <li>
                  <NavLink to="/coach-finden">Coach finden</NavLink>
                </li>
                <li>
                  <NavLink to="/faq">Häufige Fragen</NavLink>
                </li>
              </F.FooterLinks>
            </F.FooterContentUlSectionInner>

            <F.FooterContentUlSectionInner>
              <F.Title>Company</F.Title>
              <F.FooterLinks>
                <li>
                  <a href="https://www.foodiary.app/">Über Foodiary</a>
                </li>
                <li>
                  <a href="https://www.foodiary.app/get-the-app/">
                    Foodiary App
                  </a>
                </li>

                <li>
                  <a href="https://foodiary.coach/coach-werden/">Werde Coach</a>
                </li>
              </F.FooterLinks>
            </F.FooterContentUlSectionInner>

            <F.FooterContentUlSectionInner>
              <F.Title>Connect</F.Title>
              <F.FooterLinks>
                <li>
                  <a href="mailto:hello@foodiary.app">{'E-Mail'}</a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/foodiary.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
              </F.FooterLinks>
            </F.FooterContentUlSectionInner>
          </F.FooterContentUlSection>
        </F.FooterContent>

        <F.FooterInfo>
          {/* <F.FooterInfoLeft>
            <p>© {new Date().getFullYear()} All Rights Reserved.</p>
            <p>
              Apple and the Apple logo are trademarks of Apple Inc., registered
              in the U.S. and other countries. Mac App Store is a service mark
              of Apple Inc.Google Play and the Google Play logo are trademarks
              of Google Inc.
            </p>
          </F.FooterInfoLeft> */}
          <F.FooterInfoRight>
            <li>
              <a href="https://www.foodiary.app/impressum">Impressum</a>
            </li>
            <li>
              <a href="https://www.foodiary.app/datenschutz">Datenschutz</a>
            </li>
            <li>
              <a href="https://www.foodiary.app/agb">AGB</a>
            </li>
          </F.FooterInfoRight>
        </F.FooterInfo>
      </G.Container>
    </F.FooterWrap>
  )
}

export default Footer
