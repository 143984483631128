import { useHistory } from "react-router-dom";
import * as C from "./CouchCard.style";
import DefaultUserProfile from "assets/outer-pages/png/default.png";

const CouchCard = ({ isMobile, coach, baseUrl }) => {
  const history = useHistory();

  const imgUrl = coach?.profilePic
    ? `${baseUrl}${coach?.profilePic}`
    : DefaultUserProfile;

  return (
    <C.CardNew
      className="text-center"
      key={coach?.id}
      onClick={() =>
        history.push(
          `/coaches/${coach?.prename}-${coach?.surname}-${coach?.id}`,
          {
            state: { coachId: coach?.id },
          }
        )
      }
    >
      <img src={imgUrl} alt="" />
      <h3 className="text-xl">
        {coach?.prename} <br /> {coach?.surname}
      </h3>
      <C.CoachTagBox>
        <C.CoachTag className="subtitle-desktop">{coach?.subtitle}</C.CoachTag>
       
        <C.CoachTag className="subtitle-mobile">
          {coach?.subtitle?.split(" ")[0]}
        </C.CoachTag>
        <C.CoachTag className="step1">{coach?.step1}</C.CoachTag>
      </C.CoachTagBox>
    </C.CardNew>
  );
};

export default CouchCard;
