import React, { useEffect } from "react";
// Components
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper";
import QuestionAboutCoaching from "components/QuestionAboutCoaching/QuestionAboutCoaching";
import GeneralQuestion from "components/GeneralQuestion/GeneralQuestion";
import NutritionPlanQuestion from "components/NutritionPlanQuestion/NutritionPlanQuestion";
import OfferAndPrice from "components/OfferAndPrice/OfferAndPrice";
import NotTheRight from "components/NotTheRight/NotTheRight";
import DietChange from "components/DietChange/DietChange";

import hook from "assets/images/hook.svg";
// Styles
import * as G from "styles/General.Styles";
import * as C from "./faq.styles";

const CoachingPro = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            <StickyNavbar />
          </G.ContainerNavbar>
          <G.Container>
            <C.HeaderContentWrapper>
              <C.HeaderContent>
                <C.Chip>FAQ</C.Chip>
                <C.HeaderTitle className="ct-text-block font-bold color-dark text-center mt-8 text-5xl">
                  Die häufigsten Fragen <br /> über das Coaching
                </C.HeaderTitle>
                <C.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
                  Du interessierst dich für weitere Details über das Coaching? <br />
                  Hier findest du die häufigsten Fragen.
                </C.HeaderDescription>
              </C.HeaderContent>
              <div class="hook-block">
                <img width="100" src={hook} alt="hook" />
              </div>
            </C.HeaderContentWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>

      <G.Container>
        <StickyNavbar />
        {/* <SectionWrapper margin="70px 0 100px" mobileMargin="50px 0 60px">
          <QuestionAboutCoaching />
        </SectionWrapper> */}
        <SectionWrapper margin="13.8rem 0 0" mobileMargin="7.8rem 0 0">
          <GeneralQuestion />
        </SectionWrapper>
        <SectionWrapper margin="13.8rem 0 0" mobileMargin="7.8rem 0 0">
          <NutritionPlanQuestion />
        </SectionWrapper>
        <SectionWrapper margin="13.8rem 0 0" mobileMargin="7.8rem 0 0">
          <OfferAndPrice />
        </SectionWrapper>
        <SectionWrapper margin="13.8rem 0 0" mobileMargin="7.8rem 0 0">
          <NotTheRight />
        </SectionWrapper>
        <SectionWrapper margin="13.8rem 0" mobileMargin="7.8rem 0">
          <DietChange />
        </SectionWrapper>
      </G.Container>

      <FooterNew />
    </>
  );
};

export default CoachingPro;
