import styled from "styled-components";

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 600px) {

    #div_block-223-9 {
      gap: 1rem !important;
    }
    .promoted-coach-img {
      max-width: 16rem !important;
      max-height: 16rem !important;
    }
  }
`;

export const CardsWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  justify-content: space-between;
  margin-top: 80px;
  background: #f0f4f3;
  border-radius: 30px;
  padding: 80px 60px;
  align-items: center;

  @media (max-width: 991.99px) {
  }

  @media (max-width: 767px) {
    margin-top: 40px;
    padding: 40px 20px;
    grid-template-columns: 1fr;
  }
`;

export const SectionTitle = styled.h3`
  text-align: center;
  color: #142c1f;
  text-transform: uppercase;
  b {
    font-weight: 600;
  }

  @media (max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media (max-width: 600px) {
    /* display: none; */
  }
`;
export const ConsultationBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 9rem;
  }

  h4 {
    margin: 30px 0 20px;
    @media (max-width: 600px) {
      margin: 30px 0 15px;
    }
  }

  p {
  }
`;

export const ConsultationBoxIcon = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    margin: 40px 0px;
  }
  @media (max-width: 600px) {
    margin: 40px 0px;
    transform: rotate(90deg);
    img {
      width: 50px;
    }
  }
`;
export const MobileText = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  display: none;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 36px;
    display: block;
    text-align: center;
    letter-spacing: 0.48px;
    color: #142c1f;
    text-transform: uppercase;

    font-weight: 300;
    b {
      font-weight: 600;
    }
  }
`;
