import styled from "styled-components";

export const Wrap = styled.div``;

export const CardsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
  margin-top: 8rem;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top: 5rem;
  }
`;

export const SectionTitle = styled.h3``;
