import styled from "styled-components";

export const QuestionAboutCoachingContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  align-items: center;
`;

export const ContainerInner = styled.div`
  background: #f0f4f3;
  border-radius: 30px;
  padding: 80px;
  width: 100%;
  @media (max-width: 1199px) {
    padding: 40px;
  }
  @media (max-width: 991px) {
    padding: 20px 30px 40px;
  }
  @media (max-width: 600px) {
    padding: 20px 30px 40px;
  }
`;

export const Title = styled.h3``;

export const Info = styled.p``;

export const bottomWrap = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr 1fr;
  gap: 90px;
  align-items: center;
  @media (max-width: 1199px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
  @media (max-width: 600px) {
    gap: 30px;
  }
`;

export const SocialBox = styled.div`
  display: flex;
  align-items: center;
  a {
    color: #142c1f;
    text-decoration: none;
    margin-left: 18px;
    @media (max-width: 1199px) {
      margin-left: 12px;
    }
    @media (max-width: 600px) {
      line-height: 20px;
    }
  }
`;
