import React, { useState } from "react";
import * as C from "./accordian.styled";

import PlusIcon from "assets/images/myCoaching/Icon-plus.svg";
import CloseIcon from "assets/images/myCoaching/Icon-Close.svg";

const Question = ({ title, info, id, img, onExpanded, activeQuestion }) => {
  const [expanded, setExpanded] = useState(null);

  return (
    <C.AccordianBox className="question">
      <C.AccordianHeader>
        <C.AccordianTitle
          className="question-title ct-text-block text-xl accordion-title font-medium color-dark py-5 pr-8"
          active={expanded === activeQuestion?.id}
          onClick={() => {
            setExpanded(id);
            onExpanded();
          }}
        >
          {title}
        </C.AccordianTitle>
        {expanded === activeQuestion?.id && (
          <C.AccordianButton className="btn" onClick={() => setExpanded(null)}>
            <img className="IconPlus" src={CloseIcon} alt="close" />
          </C.AccordianButton>
        )}
        {expanded !== activeQuestion?.id && (
          <C.AccordianButton
            className="btn"
            onClick={() => {
              onExpanded();
              setExpanded(id);
            }}
          >
            <img className="IconClose" src={PlusIcon} alt="plus" />
          </C.AccordianButton>
        )}
      </C.AccordianHeader>
      {expanded === activeQuestion?.id && (
        <>
          <p
            className="ct-text-block color-dark text-paragraph mb-6"
            dangerouslySetInnerHTML={{
              __html: info,
            }}
          ></p>
          {img && (
            <C.VisualContent>
              <img src={img} alt="accord" />
            </C.VisualContent>
          )}
        </>
      )}
    </C.AccordianBox>
  );
};

export default Question;
