import styled from "styled-components"

const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 40px;
  
  margin-bottom: 65px;
  @media (max-width:600px){
     
    font-size: 30px;
    line-height: 45px;
    margin-bottom:35px;
  }
`

const SliderCard = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  /* background: white;
  box-shadow: 0px 0px 50px #d6d6d6a2;
  border-radius: 8px;
  padding: 1.5rem 6rem; */

  .slick-slider {
    padding-bottom: 4rem;
  }

  @media (max-width: 991.99px) {
    padding: 1rem;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`

const SliderItemCard = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  padding: 1.5rem;

  @media (max-width: 991.99px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    gap: 0rem;
  }
`

const UserProfile = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-60px, -60px);
  > img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
    border: 6px solid white;
    box-shadow: 0px 0px 17px #d6d6d6a2;
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 140px;
      height: 140px;
    }
  }

  @media (max-width: 767.99px) {
   transform: translate(-30px, -60px);
    img {
      width: 110px;
      height: 110px;
    }
  }
`
const IconActions = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  box-shadow: 0px 0px 20px #d6d6d6a2;
  border-radius: 8px;
  padding: 1rem;
  width: fit-content;

  img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0;
    object-fit: contain;
    cursor: pointer;

    &:nth-child(odd) {
      height: 1.34rem;
    }
  }
`

const UserContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  h2 {
    font-size: 26px;
    color: #142c1f;
    font-family: 'RubikBold';
    font-weight: 500;
    margin-bottom: 10px;
    @media (max-width: 600px) {
        font-size:19px;
        line-height:31px;
    }
  }

  small {
    color: #1c9d87;
    font-size: 18px;
    font-style: italic;
     
    @media (max-width: 600px) {
        font-size:15px;
        line-height:23px;
    }
  }

  .name-details {
    @media (max-width: 600px) {
      display: none;
    }
  }
`

const CouchItem = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-top: 1rem;
  img {
    width: 3.5rem;
    height: 3.5rem;
    object-fit: contain;
  }

  div {
    small {
      color: #a5b5af;
      font-size: 0.9rem;
    }

    p {
      color: #142c1f;
      font-size: 1rem;
      font-weight: 500;
      margin-top: 0.23rem;
      
    }
  }
`

const UserDescription = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
   
  font-size: 18px;
  margin-top: 30px;
  @media (max-width: 600px) {
        font-size:15px;
        line-height:23px;
      }
`

const Actions = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 600px) {
    button {
      padding: 0.5rem 0.9rem;
    }
  }
`

const UserProfileForMobile = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  > img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
    border: 6px solid #f5f5f5;
    /* box-shadow: 0px 0px 17px #d6d6d6a2; */
  }

  .details {
    h2 {
      color: #142c1f;
      margin-bottom: 0.5rem;
      font-weight: 600;
      font-size: 1rem;
    }

    small {
      color: #a5b5af;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }

    .icons {
      margin-top: 0.5rem;
      .icon {
        background: #f5f5f5;
        padding: 0.7rem;
        border-radius: 50%;
        cursor: pointer;

        img {
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 0;
          object-fit: contain;

          &:nth-child(odd) {
            /* height: 1.34rem; */
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export {
  SliderCard,
  SliderItemCard,
  IconActions,
  UserProfile,
  UserProfileForMobile,
  UserContent,
  UserDescription,
  CouchItem,
  Actions,
  Title,
}
