import styled from "styled-components"

export const CoachingWithMeContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`

export const ContentSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})``

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 60px;
  font-family: 'RubikBold';
`

export const SubTitle = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
   
  font-size: 18px;
  line-height: 28px;
  margin-top: 50px;
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: white;
  box-shadow: 0px 50px 99px #02332a1a;
  border-radius: 30px;
  padding: 20px;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  margin-left: auto;

  img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    height: 100%;
    margin-inline: auto;
  }

  @media (max-width: 767.99px) {
    height: 400px;
  }
`
