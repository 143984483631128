import { useSelector } from "react-redux"
import Slider from "react-slick"
import { PopupButton } from "react-calendly"
import * as C from "./cardSlider.style"
import StepCard from "components/general/stepCard/StepCard"
// Images
import IconFood from "assets/images/myCoaching/Icon-food.svg"

const TestimonialSlider = ({ list, baseUrl }) => {
  const settings = {
    className: "",
    slidesToShow: "1",
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }

  return (
    <>
      <C.SliderCard className="cardSlider">
        <Slider className="slick-slider" {...settings}>
          <StepCard
            title="Deine Anfrage"
            description="Klicke auf den 
          Button „Termin 
          vereinbaren“. Im 
          Anschluss öffnet sich 
          der Terminkalender. 
          Hier wählst du 
          flexibel deinen 
          Wunschtermin."
            icon={IconFood}
          />
          <StepCard
            title="Das Gespräch"
            description="Während unserem 
          Erstgespräch gehen 
          wir auf deine 
          Wünsche und 
          Bedürfnisse ein und 
          erstellen deinen 
          Ernährungsplan."
            icon={IconFood}
          />
          <StepCard
            title="Gemeinsam ans Ziel"
            description="Du entscheidest 
          unverbindlich ob wir 
          dich während deiner 
          Ernährungsumstellun
          g betreuen dürfen."
            icon={IconFood}
          />
        </Slider>
        {/* <Button
          className="custum-btn"
          gradient="linear-gradient(102deg, #2ECF96 0%, #1C9D87 100%)"
          margin="0"
          absolute={{ height: "auto" }}
          buttonStyles={{
            fontSize: "20px",
            backgroundColor: "transparent",
            color: "#ffffff",
          }}
        >
          Termin vereinbaren
        </Button> */}
        <PopupButton
          className="btn-primary custum-btn"
          url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
          styles={{
            margin: "0 0 35px",
            fontSize: "20px",
            width: "auto",
            background: "transparent",
            color: "#1C9D87",
            border: "2px solid #1C9D87",
            borderRadius: "8px",
          }}
          rootElement={document.getElementById("root")}
          text="Termin vereinbaren"
        />
      </C.SliderCard>
    </>
  )
}

export default TestimonialSlider
