import { ArrowRightCircle } from "react-feather";
import SliderCoachCard from "components/SliderCoachCard/SliderCoachCard";
import * as C from "./PromotedCoaches.style";

const PromotedCoaches = ({ promotedCoaches, baseUrl }) => {
  const renderGreenCard = () => {
    return (
      <div className="scroll-item h-100">
        <div className="card-green item-w h-100">
          <div className="chip bg-black-chip">NEU</div>
          <h4>Unsere neuen Foodiary Coaches</h4>
          <div className="action d-flex justify-content-end">
            <ArrowRightCircle strokeWidth={1} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="green-card-mobile">{renderGreenCard()}</div>
      <section className="scroll-snap-horizontal">
        <div className="green-card-desktop scroll-item">
          {renderGreenCard()}
        </div>
        {promotedCoaches?.map((coach) => (
          <div className="scroll-item">
            <SliderCoachCard
              coach={coach}
              baseUrl={baseUrl}
              key={coach?.id}
              isWidth
            />
          </div>
        ))}
      </section>
    </>
  );
};

export default PromotedCoaches;
