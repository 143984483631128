import styled from "styled-components"

export const CoachingWithMeContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`

export const ContentSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 40px;
  
  line-height: 60px;
  margin-bottom: 29px;
  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 45px;
  }
`

export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
   
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 60px;
  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 23px;
  }
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    display: block;
    /* box-shadow: 0px 10px 30px #0000004d; */
    max-width: 550px;
    max-height: 400px;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`
