import Close from "assets/images/navbar/Foodiary-modal-close-icon.svg"
import { useState, useEffect } from "react"
import * as M from "./PaymentModal.style"
import axios from "axios"

const PaymentModal = ({ isOpen, onClose, couchProfile }) => {
  const [planToggle, setPlanToggle] = useState(false)
  const [termsAndCondition, setTermsAndCondition] = useState(false)
  const [coachingPlan, setCoachingPlan] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const planTitle = "Foodiary Coaching PRO"
  const cancelTitle = "Keine Kündigung erforderlich"
  const planDuration = planToggle
    ? "Einmalig zahlen - 12 Monate profitieren."
    : "3 Monate zahlen - 12 Monate profitieren."
  const planPrice = planToggle ? "450,00 €" : "150,00 €"
  const planPriceTitle = planToggle ? "3 Abbuchungen" : "1 Abbuchungen"

  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden"
    if (!isOpen) document.body.style.overflow = "unset"
  }, [isOpen])

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      if (termsAndCondition && coachingPlan) {
        const paymentType = planToggle ? "one-time" : "monthly"
        const {
          data: { data: url },
        } = await axios.post(`https://app.foodiary.app/api/app/coach/stripe-charge`, {
          trainerID: couchProfile.trainerID,
          payment_type: paymentType,
          success_url: couchProfile.booking_page_pro,
          cancel_url: `${window.location.href.split("?")[0]}?success=false`,
        })
        window.location.href = url
        setIsError(false)
      } else {
        setIsError(true)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <>
      {isOpen && (
        <M.ModalContainer id="myModal" className="modal-container">
          <M.Modal className="modal">
            <M.ModalHeader className="modal-header">
              <M.Close className="close" onClick={onClose}>
                <img src={Close} alt="close" />
              </M.Close>
            </M.ModalHeader>
            <M.ModalContent className="modal-content">
              <M.Title>Foodiary Coaching PRO</M.Title>
              <M.PaymentType>
                <M.MonthlyPayment active={!planToggle}>
                  Monatliche Zahlung
                </M.MonthlyPayment>
                <M.ToggleSwitch>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="planToggle"
                      checked={planToggle}
                      onChange={(e) => {
                        setPlanToggle(e.target.checked)
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </M.ToggleSwitch>
                <M.OneTimePayment active={planToggle}>
                  Einmalige Zahlung
                </M.OneTimePayment>
              </M.PaymentType>
              <M.PaymentBoxContainer>
                <M.PaymentLeftBox>
                  <M.Logo>
                    <span>PRO</span>
                  </M.Logo>
                  <M.PaymentInfo>
                    <M.PlanTitle>{planTitle}</M.PlanTitle>
                    <M.CancelTitle>{cancelTitle}</M.CancelTitle>
                    <M.PlanDuration>{planDuration}</M.PlanDuration>
                  </M.PaymentInfo>
                </M.PaymentLeftBox>
                <M.PaymentRightBox>
                  <M.Price>{planPrice}</M.Price>
                  <M.PriceTitle>{planPriceTitle}</M.PriceTitle>
                </M.PaymentRightBox>
              </M.PaymentBoxContainer>
              <M.PaymentNote>
                * <strong>3 Monate</strong> persönliche Ernährungsberatung und{" "}
                <strong>12 Monate</strong> Foodiary App PRO Nutzung
              </M.PaymentNote>
              <M.CheckBoxes>
                <M.FormElement>
                  <M.FormElementLabel
                    onClick={() => {
                      setTermsAndCondition(!termsAndCondition)
                      setIsError(false)
                    }}
                  >
                    Ich habe die
                    <M.GreenText>AGB</M.GreenText>
                    und die
                    <M.GreenText>Datenschutzbestimmungen</M.GreenText>
                    gelesen, verstanden und akzeptiere diese
                    <input
                      type="checkbox"
                      name="termsAndCondition"
                      checked={termsAndCondition}
                    />
                    <M.CheckMark className="checkmark"></M.CheckMark>
                  </M.FormElementLabel>
                </M.FormElement>
                <M.FormElement>
                  <M.FormElementLabel
                    onClick={() => {
                      setCoachingPlan(!coachingPlan)
                      setIsError(false)
                    }}
                  >
                    Ich stimme zu auf mein Widerruf zu verzichten, da sowohl der
                    Ernährungsplan als auch das Coaching speziell für mich
                    entwickelt wurden.
                    <input
                      type="checkbox"
                      name="coachingPlan"
                      checked={coachingPlan}
                    />
                    <M.CheckMark className="checkmark"></M.CheckMark>
                  </M.FormElementLabel>
                </M.FormElement>
              </M.CheckBoxes>
              {isError && (
                <M.ValidationError>Muss bestätigt werden</M.ValidationError>
              )}
              <M.Button onClick={onSubmit} disabled={isLoading}>Jetzt buchen</M.Button>
            </M.ModalContent>
          </M.Modal>
        </M.ModalContainer>
      )}
    </>
  )
}

export default PaymentModal
