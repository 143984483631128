import { takeLatest, put, all, call } from "redux-saga/effects";

import ApiCollections from "configs/services/apiCollections";

import CoachActionTypes from "redux/coach/coach.types";
import * as CoachActions from "./coach.actions";

/*************************************************/

export function* getTrainerID({ payload }) {
  yield put(CoachActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getTrainerID, payload.formData);
    yield put(CoachActions.trainerIdSuccess(response));
    yield put(CoachActions.loadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(CoachActions.trainerIdFailure(error));
    yield put(CoachActions.loadingStop());
  }
}

export function* getCoachList({ payload }) {
  yield put(CoachActions.loadingCoachListStart());
  try {
    const response = yield call(ApiCollections.getCoachList, payload.formData);
    yield put(CoachActions.coachListSuccess(response));
    yield put(CoachActions.loadingCoachListStop());
    payload.callback && payload.callback();
  } catch (error) {
    yield put(CoachActions.coachListFailure(error));
    yield put(CoachActions.loadingCoachListStop());
  }
}

export function* getPromotedCoachList({ payload }) {
  yield put(CoachActions.loadingPromotedCoachStart());
  try {
    const response = yield call(ApiCollections.getPromotedCoachList);
    yield put(CoachActions.promotedCoachListSuccess(response));
    yield put(CoachActions.loadingPromotedCoachStop());
    payload.callback && payload.callback();
  } catch (error) {
    yield put(CoachActions.promotedCoachListFailure(error));
    yield put(CoachActions.loadingPromotedCoachStop());
  }
}

export function* getCoachFeedbacks({ payload }) {
  yield put(CoachActions.loadingCoachFeedbackStart());
  try {
    const response = yield call(ApiCollections.getCoachFeedbacks);
    yield put(CoachActions.coachFeedbackSuccess(response));
    yield put(CoachActions.loadingCoachFeedbackStop());
    payload.callback && payload.callback();
  } catch (error) {
    yield put(CoachActions.coachFeedbackFailure(error));
    yield put(CoachActions.loadingCoachFeedbackStop());
  }
}

/*************************************************/

export function* getCoachData({ payload }) {
  yield put(CoachActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getCoachData, payload.formData);
    yield put(CoachActions.coachDataSuccess(response));
    yield put(CoachActions.loadingStop());
    payload.callback && payload.callback();
  } catch (error) {
    yield put(CoachActions.coachDataFailure(error));
    yield put(CoachActions.loadingStop());
  }
}

/*************************************************/

export function* getCommissionedPromotedCoaches({ payload }) {
  yield put(CoachActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getCommissionedPromotedCoaches);
    yield put(CoachActions.commissionedPromotedCoachListSuccess(response));
    yield put(CoachActions.loadingStop());
    payload.callback && payload.callback();
  } catch (error) {
    yield put(CoachActions.commissionedPromotedCoachListFailure(error));
    yield put(CoachActions.loadingStop());
  }
}

/*************************************************/

export function* coachSagas() {
  yield all([
    yield takeLatest(CoachActionTypes.TRAINER_ID_REQUEST, getTrainerID),
    yield takeLatest(CoachActionTypes.COACH_LIST_REQUEST, getCoachList),
    yield takeLatest(
      CoachActionTypes.PROMOTED_COACH_LIST_REQUEST,
      getPromotedCoachList
    ),
    yield takeLatest(
      CoachActionTypes.COACH_FEEDBACK_REQUEST,
      getCoachFeedbacks
    ),
    yield takeLatest(CoachActionTypes.COACH_DATA_REQUEST, getCoachData),
    yield takeLatest(CoachActionTypes.COMMISSIONED_PROMOTED_COACH_LIST_REQUEST, getCommissionedPromotedCoaches),
  ]);
}

/*************************************************/
