import React from "react";
import * as F from "./coachingGlance.styled";
import Chip from "components/general/formComponents/chip/Chip";
import CoachingPlans from "components/CoachingPlans/CoachingPlans";
// Images
import Garantie from "assets/images/myCoaching/icon-garantie.svg";
import ActiveUser from "assets/images/Page3/50k-nutzer.svg";

const CoachingGlance = () => {
  return (
    <F.CoachingGlanceContainer>
      <F.CoachingGlanceLeft>
        <Chip text="PREISE" margin="0 0 3rem 0" />
        <F.GlanceTitle className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Wieso schwer, wenn <br /> es einfach geht
        </F.GlanceTitle>
        <F.GlanceText className="ct-text-block color-paragraph text-base text-left">
          Du profitierst von der Erfahrung eines professionellen
          Ernährungscoaches, erreichst dein gesetztes Ziel und veränderst dein
          Leben nachhaltig.
          <br />
          <br />
          Schluss mit dubiosen Diäten- und Ernährungsmythen. Wir kennen uns aus,
          wissen wie man eine Ernährungsumstellung richtig angeht und wir helfen
          dir diese dauerhaft durchzuführen, ohne sich im Alltag einzuschränken.
        </F.GlanceText>
        <F.GlanceTags>
          <img src={Garantie} alt="user" />
          <img src={ActiveUser} alt="user" />
        </F.GlanceTags>
      </F.CoachingGlanceLeft>
      <CoachingPlans />
    </F.CoachingGlanceContainer>
  );
};

export default CoachingGlance;
