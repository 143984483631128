import styled from "styled-components"

export const QuestionAboutCoachingContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  align-items: center;
`

export const ContainerInner = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: #F0F4F3;
  border-radius: 30px;
  padding: 80px;
  width:100%;
  @media (max-width: 1199px){
    padding:40px;
  }
  @media (max-width: 600px){
    padding:20px;
  }
`

export const GreenChip = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  background: #2ECF96;
  border-radius: 42px;
  padding: 13px 25px;
  color: #ffffff;
  text-transform: uppercase;
  font-size:16px;
  font-family: 'RubikBold';
  
`

export const Title = styled.h1.attrs({
  className: `${(props) => props.className}`,
})`
  font-size:58px;
  font-family: 'RubikBold';
  color: #142C1F;
  line-height: 90px;
  text-align: center;
  margin: 8px 0 25px;
  @media (max-width: 600px){
    font-size:27px;
    line-height: 40px;
  }
  
`

export const Info = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  font-size:18px;
   
  color: #44554C;
  line-height: 28px;
  text-align: center;
  @media (max-width: 600px){
    font-size: 15px;
    line-height: 20px;
  }
`

export const ContainerFixed = styled.p.attrs({
  className: `${(props) => props.className}`,
})`

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width:780px;
  margin:auto;
`

