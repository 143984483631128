import styled from "styled-components"

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`

export const TextContent = styled.div`
  @media (max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 50px;
`

export const VisualContent = styled.div`
  img {
    width: 100%;
    height: auto;
    max-width: 600px;
    /* max-height: 550px; */
    display: block;
    margin-left: auto;
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    img {
      margin-top: 170px;
    }
  }

  @media (max-width: 991.99px) {
    height: auto;
    order: 1;
    img {
      margin-inline: auto;
    }
  }

  @media (max-width: 767.99px) {
    display: none;
  }
`
