import styled from "styled-components";

const Section = styled.section`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: left;
  margin-block: 3rem;
  display: flex;
  gap: 2rem;
  position: relative;

  @media (max-width: 991.99px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.section`
  grid-template-columns: 1fr 1.2fr;
  gap: 3rem;

  @media (min-width: 1810px) {
    grid-template-columns: 1fr 1.5fr;
  }

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
  }
`;

const Background = styled.div`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -o-available;
  height: 550px;
  position: absolute;
  top: -2rem;
  z-index: -1;

  @media (max-width: 991.99px) {
    height: 24rem;
    top: -2rem;
  }
`;

const VisualContent = styled.div`
  min-width: 300px;
  position: relative;

  video,
  img {
    border-radius: 8px;
    object-fit: contain;
    max-height: 500px;
  }

  img {
    width: 100%;
    max-width: 500px;
    @media (min-width: 1810px) {
      max-width: 100%;
      max-height: 600px;
      object-fit: contain;
    }
  }

  .video {
    position: absolute;
    top: 0;
    /* left: 50%;
    transform: translateX(-50%); */
    width: 100%;
    height: 100%;
    max-width: 450px;
    max-height: 450px;
    border-radius: 8px;

    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      width: 12rem;
      background: white;
      border-radius: 50%;
    }

    @media (min-width: 1810px) {
      max-width: 100%;
    }

    > div {
      height: auto !important;
      width: 100% !important;
      max-width: 450px;
      margin-inline: auto;
    }
  }

  @media (max-width: 991.99px) {
    .unset {
      position: unset;
    }
    margin-inline: auto;
    .video-bg {
      height: 500px;
      object-fit: contain;
    }
  }
`;

const TextContent = styled.div`
  padding-top: 1.5rem;
  h3 {
    /* color: #142c1f;
    font-size: 2rem;
    font-weight: 400;
    strong {
      font-weight: 700;
    } */
  }

  p {
    /* margin-block: 3rem;
    font-size: 1rem;
    color: #44554c;
    font-weight: 400; */
  }

  @media (min-width: 992px) {
    min-height: 400px;
  }

  @media (max-width: 991.99px) {
    width: 100%;
    button {
      display: block;
      margin-inline: auto;
    }
  }

  @media (max-width: 600px) {
    h3 {
      br {
        display: none;
      }
    }
  }
`;

export { Section, TextContent, VisualContent, Background, Wrapper };
