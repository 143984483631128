import React from "react";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import ReactPlayer from "react-player";
import * as C from "./supportInGoals.style";

import Chip from "components/general/formComponents/chip/Chip";
import One from "assets/images/Foodiary-abnehmen.jpg";
import Two from "assets/images/Foodiary-Muskelaufbau.jpg";
import Three from "assets/images/Foodiary-gesund-essen.jpg";

const SupportInGoals = () => {
  const coach = useSelector((state) => state.coach.coach);

  const Items = [
    { title: "Abnehmen", img: One },
    { title: "Muskelaufbau", img: Two },
    { title: "Gesunde Ernährung", img: Three },
  ];

  return (
    <C.Wrap>
      <Chip text="ZIELSETZUNGEN" margin="0 auto 3rem" />
      <C.SectionTitle className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-light text-center mt-4">
        WIR UNTERSTÜTZEN DICH <br />
        <b className="font-semibold">BEI DER VERWIRKLICHUNG DEINER ZIELE</b>
      </C.SectionTitle>
      <C.MobileText className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-light text-center mt-4">
        WIR UNTERSTÜTZEN DICH
        <b className="font-semibold">BEI DER VERWIRKLICHUNG DEINER ZIELE</b>
      </C.MobileText>
      <C.CardsGrid className="mt-16">
        {Items.map((item) => (
          <a
            id="div_block-37-54-1"
            class="ct-link bg-white shadow-md rounded-xl overflow-hidden relative hover-float"
            href="javascript:void(0);"
          >
            <img
              id="image-39-54-1"
              alt="minerals-in-the-body-1024x576"
              src={item?.img}
              class="ct-image"
              data-id="image-39-54"
            />
            <div
              id="text_block-28-54-1"
              class="ct-text-block p-6 text-center color-dark"
              data-id="text_block-28-54"
            >
              <p className="text-2xl font-semibold">{item?.title}</p>
            </div>
          </a>
        ))}
      </C.CardsGrid>
    </C.Wrap>
  );
};

export default SupportInGoals;
