import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { PopupButton } from "react-calendly"
import * as C from "./navbar.style"

// Images
import IconDownload from "assets/images/navbar/Icon-mobile.svg"
import IconLogin from "assets/images/navbar/Icon-Log-in.svg"
import IconClose from "assets/images/navbar/Icon-Close.svg"
import MenuIcon from "assets/images/Page4/menu.svg"
import Logo from "assets/images/Foodiary-Logo.png"

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [isSticky, setIsSticky] = useState(false)


  useEffect(() => {
    window.addEventListener("scroll", getSticky)
    return () => {
      window.removeEventListener("scroll", getSticky)
    }
  }, [])

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showMobileMenu]);

  const getSticky = (e) => {
    const scrollTop = window.scrollY
    if (scrollTop >= 50) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }

  return (
    <>
      <C.Navbar isSticky={isSticky}>
        <NavLink
          to="/mein-coaching"
          exact
        >
          <C.Logo src={Logo} alt="logo" />
        </NavLink>
        <C.NavItems>
          {/* <li>
            <NavLink
              to="/mein-coaching"
              exact
              activeClassName="active-nav-link"
            >
              Mein Coaching
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/erstgespraech" exact activeClassName="active-nav-link">
              Erstgespräch
            </NavLink>
          </li>

          <li>
            <NavLink to="/coaching-pro" exact activeClassName="active-nav-link">
              Coaching PRO
            </NavLink>
          </li>
          <li>
            <NavLink to="/coach-finden" exact activeClassName="active-nav-link">
              Coach finden
            </NavLink>
          </li>
          <li>
            <NavLink to="/faq" exact activeClassName="active-nav-link">
              FAQ
            </NavLink>
          </li>
          <li>
            <PopupButton
              className="btn-primary"
              styles={{ margin: "0 0 0 1rem" }}
              url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
              rootElement={document.getElementById("root")}
              text="Termin buchen"
            />
          </li>
        </C.NavItems>
        <C.MobileNavItems>
          <li>
            <PopupButton
              className="btn-primary btn-sm"
              styles={{ position: 'relative', right: '20px' }}
              url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
              rootElement={document.getElementById("root")}
              text="Termin buchen"
            />
          </li>
          <li>
            <img
              src={MenuIcon}
              alt="menu"
              onClick={() => {
                setShowMobileMenu(!showMobileMenu)
              }}
            />
          </li>
        </C.MobileNavItems>
      </C.Navbar>

      {showMobileMenu && (
        <C.MobileNavbar className="animate__animated animate__pulse">
          <C.Header>
            <NavLink
              to="/mein-coaching"
              exact
            >
              <C.MobileNavbarLogo src={Logo} alt="logo" />
            </NavLink>
            <C.Close
              className="animate__animated animate__pulse"
              src={IconClose}
              alt="close"
              onClick={() => {
                setShowMobileMenu(!showMobileMenu)
              }}
            />
          </C.Header>
          <C.NavbarItemsWrapper>
            <C.MobileNavbarItems>
              {/* <li>
                <NavLink
                  to="/mein-coaching"
                  exact
                  activeClassName="active-nav-link"
                >
                  Mein Coaching
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/erstgespraech"
                  exact
                  activeClassName="active-nav-link"
                >
                  Erstgespräch
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/coaching-pro"
                  exact
                  activeClassName="active-nav-link"
                >
                  Coaching PRO
                </NavLink>
              </li>
              <li>
                <NavLink to="/coach-finden" exact activeClassName="active-nav-link">
                  Coach finden
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/faq"
                  exact
                  activeClassName="active-nav-link"
                >
                  FAQ
                </NavLink>
              </li>
              <li>
                <C.Divider />
              </li>
              <li>
                <a href="https://www.foodiary.app/get-the-app/">
                  <C.AppDownloadItem>
                    <img src={IconDownload} alt="comment" /> App herunterladen
                  </C.AppDownloadItem>
                </a>
              </li>
              <li>
                <a href="https://login.foodiary.app/">
                  <C.LoginItem>
                    <img src={IconLogin} alt="comment" /> Login
                  </C.LoginItem>
                </a>
              </li>
            </C.MobileNavbarItems>
            {/* <Button
                gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1))"
                margin="30px 0 15px 0"
                absolute={{ width: "auto", height: "auto" }}
                buttonStyles={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <img src={IconMessage} alt="comment" /> Erstgespräch vereinbaren
              </Button> */}
            <PopupButton
              className="btn-primary w-100"
              url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
              rootElement={document.getElementById("root")}
              text={"Erstgespräch vereinbaren"}
            />
          </C.NavbarItemsWrapper>
        </C.MobileNavbar>
      )}
    </>
  )
}

export default Navbar
