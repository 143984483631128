import styled from "styled-components"

export const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};

  @media (max-width: 600px) {
    margin: ${(props) => props.mobileMargin || props.margin};
    padding: ${(props) => props.mobilePadding || props.padding};
  }
`
