import styled from "styled-components";

export const CoachingWithMeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`;

export const ContentSection = styled.div`
  @media (max-width: 991.99px) {
    /* order: 2; */
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  position: relative;

  @media (max-width: 991.99px) {
    width: fit-content;
    br {
      display: none;
    }
  }
`;

export const TitleMobile = styled.h3``;

export const SubTitle = styled.p`
  color: #a5b5af;
  margin: 35px 0 30px 0;
`;

export const Description = styled.p`
  color: #44554c;
`;

export const VisualContent = styled.div`
  img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
  }

  > div {
    width: 100% !important;
    max-width: 600px;
    height: auto !important;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin-inline: auto;
    order: 1;
  }
`;

export const Logo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 8rem;
  position: absolute;
  left: 0;
  top: 60%;
  transform: translate(0, -50%);

  @media (max-width: 700px) {
    left: 50%;
    top: 30px;
    transform: translate(-50%, -15px);
  }
`;
