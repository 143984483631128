import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
  }

  @media(max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 40px;
  
`

export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
   
  font-size: 18px;
  color: #7a8780;
  margin: 35px 0 20px;
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`

  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media(max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin-inline: auto;
    order: 1;
  }
`