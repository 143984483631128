import styled from "styled-components";

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})``;

export const CardsWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 80px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionTitle = styled.h3`
  @media (max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SectionTitleMobile = styled.h3`
  @media (min-width: 600.99px) {
    display: none;
  }
`;

export const SubInfo = styled.p`
  max-width: 750px;
`;
