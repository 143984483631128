import React from 'react';
import Chip from "components/general/formComponents/chip/Chip";
// Images
import Foodiaryteam from 'assets/images/partnership/Foodiary-team.png';
import WhatsApp from 'assets/images/partnership/whatsapp.jpg';
import Foodiary from 'assets/images/partnership/foodiary.jpg';
import Mail from 'assets/images/partnership/mail.jpg';

import * as C from './UnbeatableTeam.style';

const UnbeatableTeam = ({ onPaymentModal }) => {
  return (
    <C.CoachingWithMeContainer>
      <C.ContentSection>
        <Chip text="KOMMUNIKATION" margin="0 0 30px 0" />
        <C.Title>
          Wir sind ein unschlagbares Team!
        </C.Title>
        <C.Description>
          Die Kommunikation ist das A und O beim Coaching. Deshalb erhältst du während der Ernährungsumstellung einen eigenen Ernährungscoach. Diesen kannst du jederzeit über WhatsApp, deinen Coaching-Bereich oder per E-Mail erreichen. Wir versprechen dir, dass deine Frage innerhalb 12 Stunden beantwortet wird.
        </C.Description>
        <C.SocialBox>
          <img src={Foodiary} alt="Foodiary" />
          <img src={Mail} alt="Mail" />
          <img src={WhatsApp} alt="WhatsApp" />
        </C.SocialBox>
      </C.ContentSection>
      <C.VisualContent>
        <img src={Foodiaryteam} alt="coach" />
      </C.VisualContent>
    </C.CoachingWithMeContainer>
  )
}

export default UnbeatableTeam