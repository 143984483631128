import React from "react"
import { useSelector } from "react-redux"
import { PopupButton } from "react-calendly"
import * as C from "./consultationReallyFree.style"
import Chip from "components/general/formComponents/chip/Chip"

import KostenloseErnaehrungsberatung from "../../assets/images/partnership/kostenlose-Ernaehrungsberatung.png";

const ConsultationReallyFree = () => {
  const coach = useSelector((state) => state.coach.coach)

  return (
    <C.Wrap>
      <C.ContentSection>
        <Chip text="PREISE" margin="0 0 30px 0" />
        <C.Title>Ist das Erstgespräch wirklich kostenlos?</C.Title>
        <C.Description>
          Ja. Dank der Vision von Foodiary, die Ernährungsumstellung für jeden so einfach wie möglich zu gestalten, kann das 45-minütige Foodiary Coaching BASIC kostenlos angeboten werden. Wenn du jedoch direkt gemeinsam mit einem professionellen Ernährungscoach deine Ernährungsumstellung beginnen möchtest, dann empfehlen wir dir unser Coaching PRO.
        </C.Description>
        {/* <C.Description>
           Wenn du jedoch direkt gemeinsam mit einem professionellen Ernährungscoach deine Ernährungsumstellung beginnen möchtest, dann empfehlen wir dir unser Coaching PRO.
        </C.Description> */}
        <PopupButton
          className="btn-primary"
          url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
          styles={{ margin: "0 0 35px", fontSize: '20px', width: 'auto', background: 'transparent', color:'#1C9D87', border: '2px solid #1C9D87', borderRadius: '8px' }}
          rootElement={document.getElementById("root")}
          text="Termin vereinbaren"
        />
      </C.ContentSection>
      <C.VisualContent>
        <img src={KostenloseErnaehrungsberatung} alt="Cons" />
      </C.VisualContent>
    </C.Wrap>
  )
}

export default ConsultationReallyFree
