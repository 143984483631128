import React from 'react';
import * as F from './chip.styles';

const Chip = (props) => {
    const { margin, marginMobile, id, absolute, color, bg, ...otherProps } = props;
    return (
        <F.ChipWrap margin={margin} marginMobile={marginMobile} style={absolute} background={bg} {...otherProps}>
            <F.Text className='ct-headline uppercase text-base rounded-full font-medium' color={color}>{props.text}</F.Text>
        </F.ChipWrap>
    )
}

export default Chip
