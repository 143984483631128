import React, { useState } from "react"
import { PopupWidget, PopupButton } from "react-calendly"
import { FiltersNavbar } from "./coachFilters.style"
import Dropdown from "components/general/formComponents/dropdown/Dropdown"

const options = [
  { id: 1, value: "Alle" },
  { id: 2, value: "Abnehmen" },
  { id: 3, value: "Gesunde Ernährung" },
  { id: 4, value: "Muskelaufbau" },
]

const CouchFilters = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const onChange = (item) => {
    console.log(item)
  }

  return (
    <FiltersNavbar className="d-flex align-items-center">
      <div className="d-flex flex-wrap left">
        <Dropdown label="Ziel" options={options} onChange={onChange} />
        <Dropdown label="Geschlecht" options={options} onChange={onChange} />
        <Dropdown label="Alter" options={options} onChange={onChange} />
      </div>
      <PopupButton
        className="btn-primary rounded"
        rootElement={document.getElementById("root")}
        url={`https://calendly.com/foodiary-head-coaches/coaching-basic-terminvereinbarung-webseite-round?hide_gdpr_banner=1`}
        text="Nächsten freien Termin buchen"
      />
      {/* url="https://calendly.com/foodiary-coaching/erstgesprach-mit-coach?hide_event_type_details=1&hide_gdpr_banner=1&text_color=303030&primary_color=1c9d87" */}
    </FiltersNavbar>
  )
}

export default CouchFilters
