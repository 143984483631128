import React from 'react';
import * as F from './SmallSelection.style';
import Chip from "components/general/formComponents/chip/Chip";

// Images
import Blueberry from 'assets/images/partnership/Blueberry-Rezept-Foodiary.jpg';
import Kichererbense from 'assets/images/partnership/kichererbense-rezept.jpg';
import MangoBow from 'assets/images/partnership/mango-bow-rezept-foodiary.jpg';
import QuinoaSalat from 'assets/images/partnership/quinoa-salat-foodiary-rezept.jpg';
import CeganCouscous from 'assets/images/partnership/vegan-couscous-foodiary-rezept.jpg';

const SmallSelection = () => {
  return (
    <F.SmallSelectionContainer>
      <Chip text="REZEPTE" margin="0 0 20px 0" />
      <F.Title>
        Eine kleine Auswahl aus über 1700 Rezepten
      </F.Title>
      <F.SelectionWrap>
        <F.SelectionBox>
          <img src={Blueberry} alt=""/>
          <span>Blaubeeren-Bananen Smoothie</span>
        </F.SelectionBox>
        <F.SelectionBox>
          <img src={CeganCouscous} alt=""/>
          <span>Veganer Couscous mit Gemüse</span>
        </F.SelectionBox>
        <F.SelectionBox>
          <img src={Kichererbense} alt=""/>
          <span>Kichererbsensalat mit Süßkartoffeln</span>
        </F.SelectionBox>
        <F.SelectionBox>
          <img src={MangoBow} alt=""/>
          <span>Mango Bowl mit Chiasamen</span>
        </F.SelectionBox>
        <F.SelectionBox>
          <img src={QuinoaSalat} alt=""/>
          <span>Quinoasalat mit Brokkoli-Gemüse</span>
        </F.SelectionBox>
      </F.SelectionWrap>
    </F.SmallSelectionContainer>
  )
}

export default SmallSelection