import * as C from "./StartNutrition.style";

const StartNutrition = () => {
  return (
    <section className="start-nutrition">
      <div className="ct-section-inner-wrap mx-auto py-0">
        <div id="div_block-79-31" className="mx-auto">
          <h3 className="section-header m-0 text-4xl">
            Ernährungsberater finden, auswählen und starten
          </h3>
          <p className="section-description text-paragraph color-paragraph">
            Du möchtest deine Gesundheit verbessern und deine Ernährung
            optimieren, aber weißt nicht genau wie? Keine Sorge, ein
            professioneller Ernährungsberater kann dir dabei helfen. Bei uns auf
            der Webseite kannst du ganz einfach einen passenden Foodiary Coach
            finden. Wir bieten eine große Auswahl an qualifizierten Experten,
            die dir dabei helfen können, deine Ziele zu erreichen. Egal, ob du
            Gewicht verlieren möchtest, deine sportliche Leistung steigern oder
            einfach nur gesünder leben willst, wir haben den perfekten
            Ernährungsberater für dich. 
            Unsere Suche ist einfach zu bedienen und
            du kannst nach verschiedenen Kriterien filtern, um den Coach zu
            finden, der am besten zu deinen Bedürfnissen passt. Du kannst nach
            Spezialgebieten wie Veganismus oder Schwerpunkt suchen, aber auch
            nach Erfahrung und Ausbildungen. Lass uns dir helfen, deine
            Ernährung zu verbessern und deine Gesundheit zu fördern. Suche jetzt
            nach deinem perfekten Coach und erreiche deine Ziele schneller und
            effektiver!
          </p>
        </div>
      </div>
    </section>
  );
};

export default StartNutrition;
