import React from "react"

import Chip from "components/general/formComponents/chip/Chip"
// Images
import Coach from "assets/images/partnership/personliche-Beratung.png"
import GreenTick from "assets/images/myCoaching/Icon-check.svg"
import ShapeOutline from "assets/images/pngs/shape-outline11px.png"

import * as C from "./newAttitude.styled"

const NewAttitude = () => {

  return (
    <C.Wrap>
      {/* <C.VisualContent>
        <C.Image>
          <img className="shape" src={ShapeOutline} alt="Shape" />
          <img className="img" src={Coach} alt="Foodiary-Coaching-Bild2" />
        </C.Image> 
      </C.VisualContent> */}
      <C.VisualContentWithoutShape>
        <img className="img" src={Coach} alt="Foodiary-Coaching-Bild2" />
      </C.VisualContentWithoutShape>
      <C.TextContent>
        <Chip text="ABLAUF" margin="0 0 30px 0" />
        <C.Title>Dein Weg zu einem neuen Lebensgefühl</C.Title>
        <C.PlanBoxIcons className="procedure">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ProcedureTop">
              Lerne deinen Ernährungscoach in einem unverbindlichen Gespräch kennen.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ProcedureTop">
              Erstelle gemeinsam mit deinem Coach deinen persönlichen Ernährungsplan.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ProcedureTop">
              Stelle deine Fragen rund um das Thema Ernährung und profitiere von dem erstklassigen Expertenwissen.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ProcedureTop">
              Entscheide dich ganz unverbindlich, ob du deine Ernährungsumstellung alleine beginnen möchtest oder von der Erfahrung und dauerhaften Betreuung profitieren möchtest.
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
      </C.TextContent>
    </C.Wrap>
  )
}

export default NewAttitude
