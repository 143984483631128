import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media(max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`

export const ContentSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media(max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 40px;
  line-height: 60px;
  
  @media (max-width:600px){
    font-size: 30px;
    line-height: 45px;
  }
`

export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554C;
   
  font-size: 18px;
  margin: 25px 0;
  @media (max-width:600px){
    font-size: 15px;
    line-height: 23px;
  }
  
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`

  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media(max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
  }
`