import React, { useState } from "react";
import * as C from "./notTheRight.styled";
// Images
import MailIcon from "assets/images/mail-green.svg";
import WhatsAppIcon from "assets/images/whatsapp.svg";

const NotTheRight = () => {
  return (
    <C.QuestionAboutCoachingContainer>
      <C.ContainerInner>
        <C.Title className="ct-headline foodiary-h2 color-dark text-3xl mb-16">
          Nicht das passende dabei?
        </C.Title>
        <C.bottomWrap>
          <C.Info>
            Kontaktiere uns einfach direkt per Mail oder über WhatsApp. Wir
            helfen dir bei deinen Anliegen gerne weiter.
          </C.Info>
          <C.SocialBox>
            <img width="40" src={MailIcon} alt="mail" />
            <a href="mailto:hello@foodiary.app" className="text-xl">
              hello@foodiary.app
            </a>
          </C.SocialBox>
          <C.SocialBox>
            <img width="40" src={WhatsAppIcon} alt="phone" />
            <a href="tel:+49 174 1953597" className="text-xl">
              +49 174 1953597
            </a>
          </C.SocialBox>
        </C.bottomWrap>
      </C.ContainerInner>
    </C.QuestionAboutCoachingContainer>
  );
};

export default NotTheRight;
