import React, { useState } from "react"
import Accordian from "components/Accordians/Accordian"
import * as C from "./nutritionPlanQuestion.styled"
// Images
import FoodiaryRezepte from "assets/images/pngs/Foodiary-Rezepte.png"

const Questions = [
  {
    id: 8,
    title: "Welche Rezepte gibt es?",
    info: `Du kannst während deiner Ernährungsumstellung aus über 1700 Rezepten wählen. Wir bieten 
    vegetarische, vegane, glutenfreie aber auch Rezepte mit Fleisch und Fisch an.`,
  },
  {
    id: 9,
    title: "Kann ich Rezepte tauschen?",
    info: `Selbstverständlich. Du kannst zu jeder Mahlzeit aus über 40 Rezepten wählen. Zudem hast du 
    die Möglichkeit eigene Rezepte anzulegen oder deine Kalorien flexibel zu tracken.`,
  },
  {
    id: 10,
    title: "Mein Ziel hat sich geändert, was nun?",
    info: `Wir empfehlen dir jedes Ziel mindestens 3 Monate zu verfolgen. Solltest du aber dennoch dein 
    Ziel ändern möchten, so ist das nach Absprache mit deinem Ernährungscoach jederzeit 
    möglich.`,
  },
  {
    id: 11,
    title: "Eignet sich der Plan für einen stressigen Alltag?",
    info: `Definitiv. Die Ernährungsplaner-App unterstützt verschiedene 
    Tagesabläufe. So richtet sich dein Ernährungsplan immer nach deinem 
    Tagesablauf und passt die Uhrzeiten der Mahlzeiten an. Zudem kannst 
    du mithilfe der Meal-Prep Funktion deine Woche einfach vorplanen und 
    vorkochen. Wir unterstützen auch einige Restaurants, sodass du auch 
    außerhalb essen kannst.`,
  },
  {
    id: 12,
    title: "Kann ich mit meinem Plan auch Muskeln aufbauen?",
    info: `Ja. Wir unterstützen sowohl dein Abnehmziel, als auch den Muskelaufbau und eine gesunde 
    Ernährung, mit welcher du dein Gewicht halten kannst.`,
  },
]

const NutritionPlanQuestion = () => {
  const [image, setImage] = useState(FoodiaryRezepte)
  const [activeQuestion, setActiveQuestion] = useState(null)

  const onToggle = (question) => {
    setImage(question?.img)
    setActiveQuestion(question)
  }

  return (
    <C.Wrap>
      <C.TextContent>
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl mb-12">Ernährungsplan:</C.Title>
        <Accordian
          data={Questions}
          activeQuestion={activeQuestion}
          onExpanded={(question) => onToggle(question)}
        />
      </C.TextContent>
    </C.Wrap>
  )
}

export default NutritionPlanQuestion
