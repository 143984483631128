import styled from "styled-components"

export const MealPlanContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-size: 40px;
  
  font-weight: 300;
  margin-bottom: 100px;
  line-height: 60px;
  

  b {
    
    font-weight: 600;
  }

  @media (max-width:600px) {
    display:none;
  }
`
export const MobileTitle = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  display:none;

  @media (max-width: 600px) {
    display:block;
    text-align: center;
    letter-spacing: 0.8px;
    color: #142c1f;
    text-transform: uppercase;
    font-size: 24px;
    
    font-weight: 300;
    margin-bottom: 100px;
    line-height: 36px;
    

    b {
      
      font-weight: 600;
    }
  }
`

export const MealPlanLower = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  gap: 80px;

  @media (max-width: 991.99px) {
    gap: 40px;
    grid-template-columns: 1fr;
  }
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  height: 600px;
  img {
    width: auto;
    height: 100%;
    display: block;
    margin-inline: auto;
  }
  @media (max-width: 991.99px) {
    margin-top: 70px;
  }

  @media (max-width: 600px) {
    height: 400px;
    display:none;
  }
`

export const CardBoxWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

export const CardBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  padding: 30px;
  display: flex;
  @media (max-width:600px){
    flex-direction: column;
    padding:20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &.active, &:hover {
    background: #ffffff;
    box-shadow: 0px 50px 99px #02332a1a;
    border-radius: 30px;
  }
`

export const CardBoxIcon = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 53px;
  height: 53px;
  background: #e5f5f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 53px;
    height: 24px;
  }
`

export const CardBoxRight = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-left: 20px;
  padding: 15px 0 0;
  @media (max-width:600px){
    margin-left: 0px;
  }
`

export const PlanTitle = styled.h4.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  font-size: 20px;
  font-family: 'RubikBold';
  margin-bottom: 19px;
  @media (max-width:600px){
    font-size: 17px;
    line-height:26px;
  }
`

export const PlanText = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #7a8780;
  font-size: 16px;
   
  @media (max-width:600px){
    font-size: 15px;
    line-height:23px;
  }
`
export const MobileImage = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display:none;
  @media (max-width:600px){
    display:block;
    padding-top:20px;
    img{
      height: 400px;
    }
  }
`