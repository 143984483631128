import styled from "styled-components";

export const Wrap = styled.div``;

export const SectionTitle = styled.h3`
  @media (max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
export const MobileText = styled.p`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
